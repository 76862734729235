import * as React from 'react';
import { ButtonToolbar, ButtonGroup, Button } from 'reactstrap';
import classNames from 'classnames'
import { motion, AnimatePresence } from "framer-motion"
import { debounce } from 'perfect-debounce';
import AccentIcons from '../../icons/Icons';
import { accentUtils, newGuid, from, t, compareObjects, showDialog } from '../../services/HelperService';
import { ConfiguratorContext } from './ConfigContextProvider';
import { PanelPatternActionBtn } from './ConfigPanelPattern';
import { ValueActionBtn } from './SelectValueDlg';
import { FabricDetailsActionBtn } from './SelectFabricDlg';
import { AccentButton } from '../AccentButton';
import { ApplyChangesErrorDlg } from './ApplyChangesErrorDlg';




const fabricSingleLineDisplayType = "SoftFurnishings.Controls.FabricOptionCtrl, SoftFurnishings";
const fabricTwoLineDisplayType = "SoftFurnishings.Controls.FabricOption2LineCtrl, SoftFurnishings";
const panelPatternDisplayType = "InsyteProductModule.Controls.PanelPatternOptionCtrl, InsyteProductModule";


export const configEvents = {

    controlGotFocus: "config_control_got_focus",
    controlLostFocus: "config_control_lost_focus",

};

export const configEventControlType = {
    singleSize: "single_size",
    decimal: "decimal",
    numeric: "numeric",
    text: "text",
};



function setOptionValueChangedForConfig2(config, optionModel, tag) {
    optionModel.UserChanged = true;
    config.DoValueChanged(optionModel, tag);
    config.ValidateOptions(true);    
}


function setOptionValueChangedForConfig(config, optionModel, tag) {
    optionModel.UserChanged = true;
    config.DoValueChangedWithValidation(optionModel, tag);
}

export function setOptionValueChanged(ctx, optionModel, tag) {

    console.log(optionModel.Label);
    setOptionValueChangedForConfig(ctx.config, optionModel, tag);
}

function setSizeChangedForConfig(config, tag) {
    config.DoProductSizeChangedWidthValidation(tag);
}

export function setSizeChanged(ctx, tag) {    
    console.log(`Fire size change : ${ctx.productID}`);

    setSizeChangedForConfig(ctx.config, tag);
}




export function onSizeChanged() {

    const fireEvent = debounce(setSizeChanged, 800, { trailing: false });

    return (ctx, sourceKey) => {
        return fireEvent(ctx, sourceKey);
    };

}



export function onSimpleOptionValueChanged() {

    const fireEvent = debounce(setOptionValueChanged, 800, { trailing: false });

    return (ctx, optionModel, newValue, tag) => {

        if (!optionModel.Locked & !optionModel.IsReadOnly) {
            optionModel.SetValue(window.InsyteConfig.OptionModelSetValueArgs.From(null, newValue));
            return fireEvent(ctx, optionModel, tag);
        }else{
            return Promise.resolve();
        }

    };

}




export function saveOrderLineOptions(line, e) {

    e.OrderLine.SaveValues(e);

    var processedOLOs = [];

    e.OptionsToSave.forEach(function (s) {

        var newOLO = {};

        s.SaveValues(e, newOLO);
        processedOLOs.push(newOLO);

    });

    e.OptionsToDelete.forEach(function (d) {
        //nothing to do
    });


    line.OrderLineOptionsData = JSON.stringify(processedOLOs);

}

export function getSizeRequirements(productSize) {

    const requireWidth = productSize?.WidthMeasurementType === window.InsyteConfig.ProductSize_WidthMeasurementTypeValues.Single
        || productSize?.WidthMeasurementType === window.InsyteConfig.ProductSize_WidthMeasurementTypeValues.TopBottom
        || productSize?.WidthMeasurementType === window.InsyteConfig.ProductSize_WidthMeasurementTypeValues.TopMiddleBottom;

    const requireHeight = productSize?.HeightMeasurementType === window.InsyteConfig.ProductSize_HeightMeasurementTypeValues.Single
        || productSize?.HeightMeasurementType === window.InsyteConfig.ProductSize_HeightMeasurementTypeValues.LeftRight
        || productSize?.HeightMeasurementType === window.InsyteConfig.ProductSize_HeightMeasurementTypeValues.LeftMiddleRight;

    const requireMultiWidth = productSize?.WidthMeasurementType === window.InsyteConfig.ProductSize_WidthMeasurementTypeValues.TopBottom
        || productSize?.WidthMeasurementType === window.InsyteConfig.ProductSize_WidthMeasurementTypeValues.TopMiddleBottom;

    const requireMultiHeight = productSize?.HeightMeasurementType === window.InsyteConfig.ProductSize_HeightMeasurementTypeValues.LeftRight
        || productSize?.HeightMeasurementType === window.InsyteConfig.ProductSize_HeightMeasurementTypeValues.LeftMiddleRight;

    return {
        requireWidth,
        requireHeight,
        requireMultiWidth,
        requireMultiHeight
    }

}


export async function applyMultiProductChanges(config, changes, multiPrductInfo, tag) {


    if (accentUtils.isNull(config)) return;

    let lastChange = null;

    try {

        while ((changes?.length ?? 0) > 0) {

            const changeItem = changes.shift();

            lastChange = changeItem;

            if (changeItem.isSize) {

                let sizeUpdated = false;

                if (config.OrderLine.WidthLinkedToSet && !accentUtils.isNull(changeItem.change.width)) {
                    config.OrderLine.Width = changeItem.change.width.newWidth;
                    config.OrderLine.WidthMeasurements = changeItem.change.width.newWidthMeasurements;
                    sizeUpdated = true;
                }

                if (config.OrderLine.HeightLinkedToSet && !accentUtils.isNull(changeItem.change.height)) {
                    config.OrderLine.Height = changeItem.change.height.newHeight;
                    config.OrderLine.HeightMeasurements = changeItem.change.height.newHeightMeasurements;
                    sizeUpdated = true;
                }

                if (sizeUpdated) {
                    setSizeChangedForConfig(config, tag);
                }

            } else {



                const memberProduct = from(multiPrductInfo).where(p => compareValues(p.ID, changeItem.change.sourceConfigID)).firstOrDefault();

                if (accentUtils.isNull(memberProduct)) return;

                const optionModels = config.GetMultiConfigLinkedOptionModelsForChange(
                    memberProduct,
                    changeItem.change.id,
                    changeItem.change.code,
                    changeItem.change.customValue,
                    changeItem.change.additionalValue,
                    changeItem.change.userSet,
                    changeItem.change.supplierID
                );


                for (var i = 0; i < optionModels.length; i++) {

                    const optionModel = optionModels[i];

                    if (!optionModel.Linked) continue;

                    setOptionValueChangedForConfig(config, optionModel, tag);
                }

            }



            //window.InsyteConfig.OptionModelSetValueArgs.From(changeItem.ID, )

        }
    } catch (e) {


        

        const ff = e;
        const gg = ff;

        await showDialog(< ApplyChangesErrorDlg config={config} expection={e} change={lastChange} />);
    }

}

export function fromInt(obj) {
    if (!accentUtils.isEmpty(obj)) {

        const v = obj.toString();

        return Number(v);

    }

    return obj;
}
export function toInt(e) {
    return accentUtils.isEmpty(e) ? 0 : window.System.Int32.parse(e.toString());
}


export function toDecimal(e) {
    return accentUtils.isEmpty(e) ? new window.System.Decimal(0) : new window.System.Decimal(e);
}

export function compareValues(v1, v2) {
    return window.Bridge.staticEquals(v1, v2);
}


export function toGuid(e) {
    return accentUtils.isEmpty(e) ? window.System.Guid.empty : window.System.Guid.parse(e);
}

export function isReadOnly(optionModel) {
    return optionModel.IsReadOnly || optionModel.Locked;
}


export function getDisplayTypes(){

    return window.InsyteConfig.ProductOptionDisplayTypeValues;
}


export function fabric_GetAllowRailroading(optionModel) {
    return window.SoftFurnishings.FabricHelper.GetAllowRailroading(optionModel);
}

export function fabric_GetAllowContinuous(optionModel) {
    return window.SoftFurnishings.FabricHelper.GetAllowContinuous(optionModel);
}

export function fabric_GetIgnoreFabricWidth(optionModel) {
    return window.SoftFurnishings.FabricHelper.GetIgnoreFabricWidth(optionModel);
}

export function fabric_GetIgnoreRailroadedFabricWidth(optionModel) {
    return window.SoftFurnishings.FabricHelper.GetIgnoreRailroadedFabricWidth(optionModel);
}

export function fabric_GetWidthAdjustment(optionModel) {
    return window.SoftFurnishings.FabricHelper.GetWidthAdjustment(optionModel);
}

export function fabric_GetDropAdjustment(optionModel) {
    return window.SoftFurnishings.FabricHelper.GetDropAdjustment(optionModel);
}

export function fabric_GetRailroadingOption(optionModel) {
    return window.SoftFurnishings.FabricHelper.GetRailroadingOption(optionModel);
}

export function fabric_GetCustomFabric(optionModel, productOptionValue) {
    return window.SoftFurnishings.FabricHelper.GetCustomFabric(optionModel, productOptionValue);
}

export function fabric_OrderLineOptionClear(optionModel) {
    optionModel.OrderLineOption.ProductOptionValueID = toGuid(accentUtils.getEmptyGuid());
    optionModel.OrderLineOption.SupplierID = toGuid(accentUtils.getEmptyGuid());
}
export function fabric_OrderLineOptionSet(optionModel, productOptionValueID, supplierID) {
    optionModel.OrderLineOption.ProductOptionValueID = productOptionValueID;
    optionModel.OrderLineOption.SupplierID = supplierID;
}

export function getOptionModel(ctx, optionID) {
    return ctx.config.GetOptionModel(toGuid(optionID));
}

export function getProductOptionValue(optionModel) {
    return optionModel.OrderLineOption?.ProductOptionValueID ? optionModel.GetProductOptionValue(optionModel.OrderLineOption?.ProductOptionValueID) : null
}


export const useActionType = (optionID) => {


    const ctx = React.useContext(ConfiguratorContext)
    const optionModel = getOptionModel(ctx, optionID);

    const option = optionModel?.GetProductOption();
    
    return {
        isPicklist: optionModel.DisplayType === getDisplayTypes().Picklist,
        isFabric: optionModel.DisplayType === getDisplayTypes().Custom && (option?.CustomDisplayType === fabricSingleLineDisplayType || option?.CustomDisplayType === fabricTwoLineDisplayType),
        isPanelPattern: optionModel.DisplayType === getDisplayTypes().Custom && (option?.CustomDisplayType === panelPatternDisplayType),
    };

};


export const useConfigInProgress = (key, config, onChange) => {


    const inProgressCount = React.useRef(0);
    const inProgress = React.useRef(false);
    const [forceRefreshKey, setForceRefreshKey] = React.useState(0);

    const onStart = React.useCallback(() => {

        

        inProgressCount.current++;
        inProgress.current = inProgressCount.current !== 0;


        if (onChange) {
            onChange(inProgress.current);
        }

        setForceRefreshKey(x => x + 1);

    }, [inProgressCount, inProgress, config, onChange, setForceRefreshKey]);

    const onFinish = React.useCallback(() => {

        

        inProgressCount.current--;
        if (inProgressCount.current < 0)
            inProgressCount.current = 0;

        inProgress.current = inProgressCount.current !== 0;


        if (onChange) {
            onChange(inProgress.current);
        }

        setForceRefreshKey(x => x + 1);

    }, [inProgressCount, inProgress, config, onChange, setForceRefreshKey]);

    React.useEffect(() => {

        if (accentUtils.isNull(config)) return;

        console.log("useConfigInProgress: (Mounted): ", key);


        config.Subscribe(configEvents.controlGotFocus, onStart, `useConfigInProgress_${key}`);
        config.Subscribe(window.InsyteConfig.InsyteConfigEvents.ChangeStart, onStart, `useConfigInProgress_${key}`);
        config.Subscribe(window.InsyteConfig.InsyteConfigEvents.SizeChanging, onStart, `useConfigInProgress_${key}`);


        config.Subscribe(configEvents.controlLostFocus, onFinish, `useConfigInProgress_${key}`);
        config.Subscribe(window.InsyteConfig.InsyteConfigEvents.SizeChangeOccurred, onFinish, `useConfigInProgress_${key}`);
        config.Subscribe(window.InsyteConfig.InsyteConfigEvents.ChangeOccurred, onFinish, `useConfigInProgress_${key}`);


        return () => {

            if (accentUtils.isNull(config)) return;

            config.UnSubscribe(configEvents.controlGotFocus, `useConfigInProgress_${key}`);
            config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.SizeChanging, `useConfigInProgress_${key}`);
            config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.ChangeStart, `useConfigInProgress_${key}`);

            config.UnSubscribe(configEvents.controlLostFocus, `useConfigInProgress_${key}`);
            config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.SizeChangeOccurred, `useConfigInProgress_${key}`);
            config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.ChangeOccurred, `useConfigInProgress_${key}`);
        };
    }, [config, onStart, onFinish, inProgress, inProgressCount ]);


    return inProgress;
}


function getNewValidation() {
    return {
        error: null,
        warning: null,
        stockWarning: null,
        stockError: null,
        required: false
    };
}

function getValidation(optionModel, validationResult) {

    const newValidation = getNewValidation();

    const readOnly = isReadOnly(optionModel);

    if (readOnly) return newValidation;

    newValidation.required = optionModel.CurrentValueIsNullOrEmpty && optionModel.IsMandatory;

    if (validationResult) {

        if (validationResult.OptionID === optionModel.ID && validationResult.Type !== window.InsyteConfig.ResultType.OK) {

            if (validationResult.Type === window.InsyteConfig.ResultType.Error) {
                newValidation.error = validationResult.Message;
                newValidation.required = true;
            } else if (validationResult.Type === window.InsyteConfig.ResultType.Warning) {
                newValidation.warning = validationResult.Message;
            }
        }
    }
   

    if (!accentUtils.isNull(optionModel.OutOfStockDiscontinuedResult)) {

        if (optionModel.OutOfStockDiscontinuedResult.IsDiscontinued) {
            newValidation.stockError = optionModel.OutOfStockDiscontinuedResult.GetMessage();
        } else {
            newValidation.stockWarning = optionModel.OutOfStockDiscontinuedResult.GetMessage();
        }

    }

    return newValidation;
}



class ValidationManager {

    constructor(optionID, validation, setValidation) {
        this.queue = [];

        this.optionID = optionID;
        this.currentValidation = validation;
        this.notifyValidation = setValidation;

        this.enqueue = this.enqueue.bind(this);
        this.last = this.last.bind(this);
        this.process = this.process.bind(this);
        this.processDebounce = debounce(this.process, 300, { trailing: false });
        

    }

    process() {

        

        const msg = this.last();
        if (compareObjects(msg, this.currentValidation)) return;

        if (this.optionID === "ABCF2FD5-8223-4F09-8E47-0DF2188DA63E") {
            const aa = 1;
            const bb = aa;

            console.log(`ValidationManager: ${msg.Error}`);

        }


        this.notifyValidation(msg);
        this.currentValidation = msg;
    }

    enqueue(validation) {

        if (this.optionID === "ABCF2FD5-8223-4F09-8E47-0DF2188DA63E") {
            const aa = 1;
            const bb = aa;
        }


        this.queue.push(validation);
        this.processDebounce();
    }

    last() {
        const result = from(this.queue).last();

        this.queue = [];

        return result;
    }
}


export const useValidation = (optionID, key) => {


    
    const ctx = React.useContext(ConfiguratorContext)
    const optionModel = getOptionModel(ctx, optionID);

    const changed = useChanged(optionID, `OptionValidationHook_${key}_${optionID}`, true);
    const sizeChanged = useSizeChanged(optionID, `OptionValidationHook_${key}_${optionID}`);


    const [validation, setValidation] = React.useState(getValidation(optionModel));

    const validationManager = React.useRef(new ValidationManager(optionID, validation, setValidation));


    const onStartValidate = React.useCallback(e => {

        if (optionID === "ABCF2FD5-8223-4F09-8E47-0DF2188DA63E") {
            const aa = 1;
            const bb = aa;
        }


        validationManager.current.enqueue(getNewValidation());        
    }, [validation]);
   
    const onValidate = React.useCallback(e => {

        if (optionID === "ABCF2FD5-8223-4F09-8E47-0DF2188DA63E") {
            const aa = 1;
            const bb = aa;
        }

        

        if (optionID?.toUpperCase() === e.OptionID?.toString()?.toUpperCase()) {

            const newValidation = getValidation(optionModel, e);
            validationManager.current.enqueue(newValidation);

        }

    }, [optionModel, optionID, validationManager]);


    React.useEffect(() => {

        ctx.config.Subscribe(window.InsyteConfig.InsyteConfigEvents.Validating_Options, onStartValidate, `OptionValidationHook_${key}_${optionID}`);
        ctx.config.Subscribe(window.InsyteConfig.InsyteConfigEvents.Validate_Option_Control, onValidate, `OptionValidationHook_${key}_${optionID}`);

        const id = optionID;

        return () => {
            ctx.config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.Validating_Options, `OptionValidationHook_${key}_${id}`);
            ctx.config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.Validate_Option_Control, `OptionValidationHook_${key}_${id}`)
        };

    }, [optionID, ctx, onStartValidate, onValidate]);



    return validation;

}


export const useSizeChanged = (optionID, key, tag = null) => {

    const ctx = React.useContext(ConfiguratorContext)
    const optionModel = getOptionModel(ctx, optionID);
    const [changeID, setChangeID] = React.useState(0);
    const [hasChanged, setHasChanged] = React.useState(false);

    const onChanged = React.useCallback(e => {

        

        if ((!accentUtils.isNull(tag) && tag !== e.Tag) || (optionModel?.RefreshRequired ?? false)) {
            setChangeID(x => x + 1);
            setHasChanged(true);
        } else {
            setHasChanged(false);
        }



    }, [optionModel, setChangeID, setHasChanged, tag]);

    React.useEffect(() => {

        ctx.config.Subscribe(window.InsyteConfig.InsyteConfigEvents.SizeChangeOccurred, onChanged, `OptionSizeChangedHook_${key}_${optionID}`);

        const id = optionID;

        return () => {
            ctx.config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.SizeChangeOccurred, `OptionSizeChangedHook_${key}_${id}`);
        };

    }, [optionID, ctx, key]);


    return {
        changeID: `changeID_${changeID}`,
        hasChanged: hasChanged,
        prevValue: optionModel?.PrevValue,
        newValue: optionModel?.CurrentValueAsString
    };

}


export const useRequireRefresh = (optionID, key) => {

    const ctx = React.useContext(ConfiguratorContext)
    const optionModel = getOptionModel(ctx, optionID);

    const [refreshKey, setRefreshKey] = React.useState(0);

    React.useEffect(() => {

        if (optionModel.RefreshRequired) {
            setRefreshKey(x => x + 1);
        }

    }, [setRefreshKey, optionModel.RefreshRequired, ctx.isInProgress])

    

    return {
        refreshID: refreshKey
    };

}

export const useChanged = (optionID, key, notifyIfMe, ignoreTag) => {

    const tag = React.useRef(newGuid());
    const ctx = React.useContext(ConfiguratorContext)
    const optionModel = getOptionModel(ctx, optionID);


    const [changeID, setChangeID] = React.useState(0);
    const [hasChanged, setHasChanged] = React.useState(false);

    const requireRefresh = useRequireRefresh(optionID, key);


    const onChanged = React.useCallback(e => {

        

        const wasMe = e.ID.toString().toUpperCase() === optionID.toUpperCase() && (ignoreTag || tag.current === e.Tag);

        if (optionModel.RefreshRequired && (notifyIfMe || !wasMe)) {
            if (!wasMe) {
                setChangeID(x => x + 1);
            }
            setHasChanged(true);
        } else {
            setHasChanged(false);
        }

        

    }, [optionModel, optionID, setChangeID, setHasChanged, tag]);

    React.useEffect(() => {

        ctx.config.Subscribe(window.InsyteConfig.InsyteConfigEvents.ChangeOccurred, onChanged, `OptionChangedHook_${key}_${optionID}`);

        const id = optionID;

        return () => {
            ctx.config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.ChangeOccurred, `OptionChangedHook_${key}_${id}`);
        };

    }, [optionID, ctx, key]);


    return {
        changeID: `changeID_${changeID}_${requireRefresh.refreshID}`,
        prevValue: optionModel.PrevValue,
        newValue: optionModel.CurrentValueAsString,
        hasChanged: hasChanged,
        tag: tag.current
    };

}



export const ConfigLabel = props => {

    const option = props.option;    
    const label = option.Description;


    return <div className="config-label" data-optionid={option?.ID?.toString()}>
        <IndicatorBar optionID={ option?.ID?.toString() } />
        <div>{label}</div>        
        <ConfigInfo option={option} />
    </div>

};

export const IndicatorBar = React.memo(props => {

    return<div className="config-indicator-bar">
            <ChangeWarningIndicator optionID={ props.optionID} />
            <ErrorWarningIndicator optionID={props.optionID} />
            <RequiredIndicator optionID={props.optionID} />
            <OptionLinkIndicator optionID={props.optionID} />
        </div>;
});

export const RequiredIndicator = props => {


    const ctx = React.useContext(ConfiguratorContext)
    const optionModel = getOptionModel(ctx, props.optionID);

    let indicator = "";

    if (optionModel.MandatoryForQuoting) {
        indicator = "*";
    } else if (optionModel.MandatoryForOrdering) {
        indicator = "**"
    }

    const className = classNames("config-required-indicator",
        {
            "quoting": indicator.length === 1,
            "ordering": indicator.length === 2,
        }
    );

    return <div className={className} >
        { indicator}
    </div>

};

export const ChangeWarningIndicator = props => {

    
    const changed = useChanged(props.optionID, "ChangeWarningIndicator", false, true);
    const sizeChanged = useSizeChanged(props.optionID, "ChangeWarningIndicator");

    const beforeAndAfterIsEmpty = (accentUtils.isEmpty(changed.prevValue) && accentUtils.isEmpty(changed.prevValue) === accentUtils.isEmpty(changed.newValue)) || (accentUtils.isEmpty(sizeChanged.prevValue)  && accentUtils.isEmpty(sizeChanged.prevValue) === accentUtils.isEmpty(sizeChanged.newValue));

    let prev = null;

    if (changed.hasChanged) {
        prev = accentUtils.isEmpty(changed.prevValue) ? "<EMPTY>" : changed.prevValue;
    }

    if (sizeChanged.hasChanged) {
        prev = accentUtils.isEmpty(sizeChanged.prevValue) ? "<EMPTY>" : sizeChanged.prevValue;
    }

    const hasValue = (changed.hasChanged || sizeChanged.hasChanged) && !beforeAndAfterIsEmpty;

    //const msg = hasValue ? <AccentIcons.WarningChanged toolTipText={`Changed from ${prev}`} noPadding /> : null;
    const msg = <AccentIcons.WarningChanged toolTipText={`Changed from ${prev}`} noPadding /> ;

    const animation = !hasValue ? "none" : "displayed";

    if (props.optionID === "7A5BA7B3-14A7-4902-97A7-0C46008077F8") {
        const aa = 1;
        const bb = aa;
    }


    return <motion.div className="config-change-indicator"
        key={props.optionID}
        initial={{ opacity: 0, scale: 0 }}
        animate={animation}
        variants={{
            "none": {
                opacity: 0,
                scale: [1, 0],
                transition: { duration: 1}
            },
            "displayed": {
                opacity: 1,
                scale: [0, 1]
            }
        }}
        transition={{
            duration: 1
        }}
    >
        {msg}
    </motion.div>    

};





export const SizeIndicator = React.memo(props => {

    const ctx = React.useContext(ConfiguratorContext)

    const [error, setError] = React.useState(null);


    const clearError = React.useCallback(e => {
        setError(null);

    }, [setError]);


    const onError = React.useCallback(e => {
        setError(e);

    }, [setError]);


    React.useEffect(() => {

        ctx.config.Subscribe(window.InsyteConfig.InsyteConfigEvents.SizeChanging, clearError, `SizeIndicator_${props.width}_${ctx.productID}`);



        if (props.width)
            ctx.config.Subscribe(window.InsyteConfig.InsyteConfigEvents.Size_Width_Error, onError, `SizeIndicator_${props.width}_${ctx.productID}`);

        if (!props.width)
            ctx.config.Subscribe(window.InsyteConfig.InsyteConfigEvents.Size_Height_Error, onError, `SizeIndicator_${props.width}_${ctx.productID}`);

        ctx.config.Subscribe(window.InsyteConfig.InsyteConfigEvents.Size_Area_Error, onError, `SizeIndicator_${props.width}_${ctx.productID}`);
        ctx.config.Subscribe(window.InsyteConfig.InsyteConfigEvents.Size_General_Error, onError, `SizeIndicator_${props.width}_${ctx.productID}`);

        return () => {

            ctx.config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.SizeChanging, `SizeIndicator_${props.width}_${ctx.productID}`);

            if (props.width)
                ctx.config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.Size_Width_Error, `SizeIndicator_${props.width}_${ctx.productID}`);
            if (!props.width)
                ctx.config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.Size_Height_Error, `SizeIndicator_${props.width}_${ctx.productID}`);
            ctx.config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.Size_Area_Error, `SizeIndicator_${props.width}_${ctx.productID}`);
            ctx.config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.Size_General_Error, `SizeIndicator_${props.width}_${ctx.productID}`);

        };

    }, [ctx.productID]);


    let ctrl = null;

    
    if (!accentUtils.isEmpty(error)) {
        ctrl = <SizeErrorIndicator id={'${props.width}_${ctx.productID}'} error={error} />;
    } else {
        ctrl = <div></div>;
    }


    let linkedSizeCtrl = null;

    if (props.width) {
        linkedSizeCtrl = <SizeLinkBtn noPadding getLinked={ol => ol.WidthLinkedToSet} toggleLinked={ol => {
            ol.WidthLinkedToSet = !ol.WidthLinkedToSet;
        }} />;
    } else {
        linkedSizeCtrl = <SizeLinkBtn noPadding getLinked={ol => ol.HeightLinkedToSet} toggleLinked={ol => {
            ol.HeightLinkedToSet = !ol.HeightLinkedToSet;
        }} />;
    }
    

    return <div className={`config-size-indicator`} >
        <div></div>        
        {ctrl}
        <div>{linkedSizeCtrl}</div>
    </div>;

});



export const ErrorWarningIndicator = React.memo(props => {

    const validation = useValidation(props.optionID, "ErrorWarningIndicator");

    //const type = !accentUtils.isEmpty(validation.error) ? "error" : !accentUtils.isEmpty(validation.warning) ? "warning" : "empty";

    let ctrl = null;

    if (!accentUtils.isEmpty(validation.error)) {
        ctrl = <ErrorIndicator id={props.optionID} error={ validation.error} />;
    } else if (!accentUtils.isEmpty(validation.warning)) {
        ctrl = <WarningIndicator id={props.optionID} warning={ validation.warning} />;
    } else {
        ctrl = null;
    }





    return <div className={`config-error-warning-indicator`} >
        <AnimatePresence>
            {ctrl}
        </AnimatePresence>
    </div>;

});

export const WarningIndicator = props => {

    return <motion.div
        className="config-warning-indicator"
        key={`warning_${props.id}`}
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: [0, 1.5, 1] }}
        exit={{ opacity: 0, scale: [1, 0], rotate: [360, 720, 1080, 2160, 2520, 0] }}
        transition={{ duration: 1 }}
    >
        <AccentIcons.Warning toolTipText={props.warning} outlined />
    </motion.div>   
};

export const ErrorIndicator = React.memo(props => {


    return <motion.div
        className="config-error-indicator"
        key={`error_${props.id}`}
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: [0, 1.5, 1] }}
        exit={{ opacity: 0, scale: [1, 0], rotate: [360, 720, 1080, 2160, 2520, 0] }}
        transition={{ duration: 1 }}
    >
        <AccentIcons.Error toolTipText={props.error} outlined />
    </motion.div>
});

export const SizeErrorIndicator = React.memo(props => {


    return <motion.div
        className="config-error-indicator"
        key={`error_${props.id}`}
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: [0, 1.5, 1] }}        
        transition={{ duration: 1 }}
    >
        <AccentIcons.Error toolTipText={props.error} outlined />
    </motion.div>
});

export const ConfigInfo = props => {

    const notes = props.option?.Notes;

    if (accentUtils.isEmpty(notes)) return null;

    return <div className="config-info">
        <AccentIcons.Info toolTipText={notes} />
    </div>;

};

export const SizeLinkBtn = props => {

    
    const ctx = React.useContext(ConfiguratorContext);
    const multiCtx = ctx.getMultiConfigManager();

    const orderLine = ctx.config.OrderLine;

    const [linked, setLinked] = React.useState(props.getLinked(orderLine));
    



    const onToggle = e => {

        props.toggleLinked(orderLine);
        setLinked(l=> !l);
        ctx.notifyDirty();
    };



    if (accentUtils.isNull(multiCtx)) return null;

    if (!multiCtx.allowSizeLinking(orderLine)) return null;


    if (linked) {
        return <AccentIcons.Link top={ props.top} style={{ color: "Blue", ...props.style }} onClick={onToggle} noPadding={props.noPadding} />;
    }

    return <AccentIcons.LinkBroken top={props.top} style={{ color: "Red", ...props.style }} onClick={onToggle} noPadding={props.noPadding} />;
};

export const OptionLinkIndicator = props => {

    

    const ctx = React.useContext(ConfiguratorContext);
    const multiCtx = ctx.getMultiConfigManager();
    const isMultiProduct = !accentUtils.isNull(multiCtx);

    const optionModel = getOptionModel(ctx, props.optionID);

    const [linked, setLinked] = React.useState(optionModel.Linked);


    

    const onToggle = e => {
        optionModel.Linked = !optionModel.Linked;

        setLinked(optionModel.Linked);

        ctx.notifyDirty();
    };



    let content = null;


    if (isMultiProduct) {

        const showLink = multiCtx.optionHasLinks(props.optionID, ctx.config.Data.ID);

        if (showLink) {
            if (linked) {
                content = <AccentIcons.Link top="0" style={{ color: "Blue" }} onClick={onToggle} noPadding />;
            } else {
                content = <AccentIcons.LinkBroken top="0" style={{ color: "Red" }} onClick={onToggle} noPadding />;
            }
        }

        return <div className="config-linked-indicator">
            {content}
        </div>;

    }

    return null;

};


export const ActionBar = props => {

    return <div className="config-action-bar">
        <ValueActionBtn optionID={props.optionID} />
        <FabricDetailsActionBtn optionID={props.optionID} />
        <PanelPatternActionBtn optionID={props.optionID} />
    </div>
};


export const configFilterTypes = {
    all: "all",
    quote: "quote",
    order: "order",
    invalid: "invalid",
    userSet: "userSet",
    changed: "changed",
    calculations: "calculations",
};

export function isOptionVisible(optionModel, filter) {

    if ((filter?.length??0) === 0) return true;


    let result = false;

    const showCalculations = from(filter).any(f => f === configFilterTypes.calculations);

    for (var i = 0; i < filter.length; i++) {

        if (!showCalculations && (optionModel.Calculated || optionModel.HasCalculatedScript)) {
            return false;
        }


        if (filter[i] === configFilterTypes.all)
            return true;

        if (filter[i] === configFilterTypes.order && (optionModel.MandatoryForOrdering || optionModel.MandatoryForQuoting))
            return true;

        if (filter[i] === configFilterTypes.quote && optionModel.MandatoryForQuoting)
            return true;

        if (filter[i] === configFilterTypes.userSet && optionModel.UserChanged)
            return true;

        if (filter[i] === configFilterTypes.invalid && optionModel.IsInValid)
            return true;

        if (filter[i] === configFilterTypes.changed && optionModel.IsDirty)
            return true;

        
    }


    return result;

}


export const ConfigFilterBar = props => {

    const [option, setOption] = React.useState(accentUtils.isNull(props.defaultValue) ? configFilterTypes.all : from(props.defaultValue).where(x => x !== configFilterTypes.calculations).firstOrDefault() ?? configFilterTypes.all);
    const [edit, setEdit] = React.useState(accentUtils.isNull(props.defaultValue) ? configFilterTypes.calculations : from(props.defaultValue).where(x => x === configFilterTypes.calculations).firstOrDefault());


    const onEditChange = React.useCallback(e => {
        const newValue = edit === configFilterTypes.calculations ? null : configFilterTypes.calculations;
        setEdit(newValue);
        props.onChange([newValue, option].filter(x => !accentUtils.isNull(x)));
    }, [setEdit, option, edit])

    const onOptionChange = React.useCallback(e => {
        setOption(e);
        props.onChange([edit, e].filter(x => !accentUtils.isNull(x)));
    }, [setOption, option, edit])

    const iconStyle = { padding: "3px" };

    return <ButtonToolbar className="config-filter">
        <ButtonGroup className="me-2">
            <AccentButton color="primary" tagName="configFilterAll" outline active={option === configFilterTypes.all} onClick={() => onOptionChange(configFilterTypes.all)} tooltip={t(`application_strings.application.controls.configFilter.${configFilterTypes.all}_tooltip`)}>
                {t(`application_strings.application.controls.configFilter.${configFilterTypes.all}`)}
            </AccentButton>
            <AccentButton color="primary" tagName="configFilterQuote" outline active={option === configFilterTypes.quote} onClick={() => onOptionChange(configFilterTypes.quote)} tooltip={t(`application_strings.application.controls.configFilter.${configFilterTypes.quote}_tooltip`)}>
                {t(`application_strings.application.controls.configFilter.${configFilterTypes.quote}`)}
            </AccentButton>
            <AccentButton color="primary" tagName="configFilterOrder" outline active={option === configFilterTypes.order} onClick={() => onOptionChange(configFilterTypes.order)} tooltip={t(`application_strings.application.controls.configFilter.${configFilterTypes.order}_tooltip`)}>
                {t(`application_strings.application.controls.configFilter.${configFilterTypes.order}`)}
            </AccentButton>
            <AccentButton color="primary" tagName="configFilterInvalid" outline active={option === configFilterTypes.invalid} onClick={() => onOptionChange(configFilterTypes.invalid)} tooltip={t(`application_strings.application.controls.configFilter.${configFilterTypes.invalid}_tooltip`)}>
                <AccentIcons.Warning style={iconStyle} />                
            </AccentButton>
            <AccentButton color="primary" tagName="configFilterChanged" outline active={option === configFilterTypes.changed} onClick={() => onOptionChange(configFilterTypes.changed)} tooltip={t(`application_strings.application.controls.configFilter.${configFilterTypes.changed}_tooltip`)}>
                <AccentIcons.WarningChanged style={iconStyle} />                
            </AccentButton>
        </ButtonGroup>
        <ButtonGroup className="me-2">
            <AccentButton color="primary" tagName="configFilterCalc" outline active={edit === configFilterTypes.calculations} onClick={() => onEditChange()} tooltip={t(`application_strings.application.controls.configFilter.${configFilterTypes.calculations}_tooltip`)}>
                <AccentIcons.Calculate style={iconStyle} />
            </AccentButton>
        </ButtonGroup>
    </ButtonToolbar>;
};

