import * as React from 'react'
import { AccentButton } from '../controls/AccentButton';
import { isMobileOnly, usePosition } from '../controls/AccentDisplay';
import { AccentDialog } from './AccentDialog'
import { newGuid, t } from '../services/HelperService';
import AIWarningAlert from '../controls/AIWarningAlert';
import { AccentTextUnbound } from '../controls/unbound/AccentTextUnBound';
import { AccentHtmlUnbound } from '../controls/unbound/AccentHtmlUnbound';

import {
    Navbar,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import {
    ChevronDown, Edit, Layout, Heart, Book, Users,
    FileText, UserCheck, List, Eye, Shield, Globe, Sliders, Calendar
} from 'lucide-react';
import { queryNoTracking } from '../services/DataService';
import InProgressOverlay from '../app-framework/InProgressOverlay';
import { AccentTextAreaUnbound } from '../controls/unbound/AccentTextAreaUnbound';
import { apiContextEmailTypes } from '../services/JobService';
import AICreditDisplay from '../controls/AICreditDisplay';



const query_AIEditEmailAction = queryNoTracking("AIEditEmail_Action", undefined, true);




export const AIEmailEditDlg = props => {


    const elementID = React.useRef(`id_${newGuid()}`);


    const [inprogress, setInprogress] = React.useState(false);

    const [userPromt, setUserPromt] = React.useState("");
    const [body, setBody] = React.useState(props.context?.body);
    const [refreshKey, setRefreshKey] = React.useState(1);
    const [promptRefreshKey, setPromptRefreshKey] = React.useState(1);

    const pos = usePosition(elementID.current);

    const onApply = React.useCallback(e => {

        props.onClose({
            body : body
        });

    }, [props, body]);

    const onSelect = React.useCallback(async e => {

        const prompt = EditEmailPromptGenerator.getPrompt(e, props.context.EmailType);

        setUserPromt(prompt);
        setPromptRefreshKey(k => k + 1);

    }, [setUserPromt, setPromptRefreshKey, props]);


    const onSubmit = React.useCallback(async e => {

        setInprogress(true);

        const result = await query_AIEditEmailAction.getFirstOrDefault({ ...props.context, Body: body, UserPrompt : userPromt });

        setBody(result.Body);


        setRefreshKey(k => k + 1);

        setInprogress(false);

        e.promise();

    }, [props, body, setRefreshKey, setInprogress, setBody, userPromt]);



    const height = ((pos?.toScreenHeight) ?? 300) - 50;
    
    return <AccentDialog
        className="acc-aiedit-dlg"
        relativeWidth={display => display.large ? display.width - 100 : display.width}
        relativeHeight={display => display.large ? display.height - 100 : display.height}
        onClose={props.onClose}
        headerContent=""
        actionLayout="end"
        actionsContent={[<AccentButton key="4" disabled={inprogress} onClick={onApply}>{t("application_strings.application.buttons.apply")}</AccentButton>]}
    >
        <div className="d-flex flex-column gap-3">

            <AIWarningAlert />

            <InProgressOverlay text="Please wait" block={inprogress} showText={inprogress}>
                <ActionBar onSelect={onSelect} />

                

                <div className="d-flex">
                    <div className="me-auto" style={{width:"100%" , paddingRight: "30px"} }>
                        <AccentTextAreaUnbound key={`s_${promptRefreshKey}`} defaultValue={userPromt} label="Instuction" onChange={setUserPromt} rows={6 } />
                    </div>
                    <div style={{ paddingTop: "15px" }}>
                        <AICreditDisplay refreshCount={refreshKey } />
                    </div>
                </div>

                
                <AccentButton onClick={onSubmit}>Try Instruction</AccentButton>
                
                <div id={elementID.current} >
                    <AccentHtmlUnbound key={`b_${refreshKey}`} defaultValue={body} onChange={setBody} allTools minTools={isMobileOnly} height={height} />
                </div>

            </InProgressOverlay>

            
            
        </div>


    </AccentDialog>;

}

const ActionBar = ({ onSelect}) => {
    const handleAction = (action) => {

        onSelect(action);
        
    };

    const renderDropdown = ({ title, icon: Icon, options }) => (
        <UncontrolledDropdown className="me-2 mb-1">
            <DropdownToggle
                color="light"
                size="sm"
                className="d-flex align-items-center"
                style={{ minHeight: '31px' }}
            >
                <Icon size={16} className="me-2" />
                <span className="d-none d-sm-inline">{title}</span>
                <ChevronDown size={14} className="ms-1" />
            </DropdownToggle>
            <DropdownMenu className="shadow-sm py-1">
                {options.map((option) => (
                    <DropdownItem
                        key={option.key}
                        onClick={() => handleAction(option.key)}
                        className="px-3 py-1"
                    >
                        <span>{option.value}</span>
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    );

    const actions = [
        {
            title: "Custom",
            icon: Sliders,
            options: [
                { key: "custom", value: "Custom" },
            ]
        },
        {
            title: "Clarity",
            icon: FileText,
            options: [
                { key: "clarity_simplify", value: "Simplify Sentences" },
                { key: "clarity_technical", value: "Clarify Technical Terms" }
            ]
        },
        {
            title: "Culture",
            icon: Globe,
            options: [
                { key: "culture_inclusive", value: "Use Inclusive Language" },
                { key: "culture_events", value: "Acknowledge Cultural Events" }
            ]
        },
        {
            title: "Emotion",
            icon: Heart,
            options: [
                { key: "emotion_empathy", value: "Add Empathy" },
                { key: "emotion_enthusiasm", value: "Express Enthusiasm" },
                { key: "emotion_urgency", value: "Create Urgency" },
                { key: "emotion_emoji", value: "Add Emojis" }
            ]
        },
        {
            title: "Formality",
            icon: Book,
            options: [
                { key: "formality_increase", value: "Increase Formality" },
                { key: "formality_decrease", value: "Decrease Formality" }
            ]
        },
        {
            title: "Holidays",
            icon: Calendar,
            options: [
                { key: "holiday_black_friday", value: "Black Friday" },
                { key: "holiday_chinese_new_year", value: "Chinese New Year" },
                { key: "holiday_cinco", value: "Cinco de Mayo" },
                { key: "holiday_christmas", value: "Christmas" },
                { key: "holiday_diwali", value: "Diwali" },
                { key: "holiday_easter", value: "Easter" },
                { key: "holiday_eid_adha", value: "Eid al-Adha" },
                { key: "holiday_eid_fitr", value: "Eid al-Fitr" },
                { key: "holiday_fathers", value: "Father's Day" },
                { key: "holiday_halloween", value: "Halloween" },
                { key: "holiday_hanukkah", value: "Hanukkah" },
                { key: "holiday_independence", value: "Independence Day (US)" },
                { key: "holiday_kwanzaa", value: "Kwanzaa" },
                { key: "holiday_labor", value: "Labor Day" },
                { key: "holiday_midautumn", value: "Mid-Autumn Festival" },
                { key: "holiday_mothers", value: "Mother's Day" },
                { key: "holiday_newyear", value: "New Year's Day" },
                { key: "holiday_oktoberfest", value: "Oktoberfest" },
                { key: "holiday_stpatrick", value: "St. Patrick's Day" },
                { key: "holiday_thanksgiving", value: "Thanksgiving" },
                { key: "holiday_valentine", value: "Valentine's Day" }
            ]
        },
        {
            title: "Language",
            icon: Edit,
            options: [
                { key: "language_simplify", value: "Simplify Language" },
                { key: "language_professional", value: "Use Professional Terminology" }
            ]
        },
        {
            title: "Length",
            icon: Layout,
            options: [
                { key: "length_shorten", value: "Shorten Email" },
                { key: "length_expand", value: "Expand Details" }
            ]
        },
        {
            title: "Personalize",
            icon: UserCheck,
            options: [
                { key: "personalize_reference", value: "Reference Past Interactions" }
            ]
        },
        {
            title: "Perspective",
            icon: Eye,
            options: [
                { key: "perspective_customer", value: "Customer-Centric Approach" },
                { key: "perspective_company", value: "Company-Centric Approach" }
            ]
        },
        {
            title: "Persuasion",
            icon: Heart,
            options: [
                { key: "persuasion_more", value: "Use More Persuasive Language" },
                { key: "persuasion_less", value: "Use Less Persuasive Language" }
            ]
        },
        {
            title: "Structure",
            icon: List,
            options: [
                { key: "structure_bullets", value: "Use Bullet Points" },
                { key: "structure_headings", value: "Add Headings and Subheadings" }
            ]
        }
    ].sort((a, b) => a.title.localeCompare(b.title));

    return (
        <div className="bg-light border-bottom">
            <Navbar expand="true" light className="px-2 py-1">
                <div className="d-flex flex-wrap" style={{ gap: '4px' }}>
                    {actions.map((action) => (
                        <div key={action.title}>
                            {renderDropdown(action)}
                        </div>
                    ))}
                </div>
            </Navbar>
        </div>
    );
};




const EditEmailActionTypes = {
    clarity: {
        simplify: 'clarity_simplify',
        technical: 'clarity_technical'
    },

    culture: {
        inclusive: 'culture_inclusive',
        events: 'culture_events'
    },

    custom: {
        custom: 'custom',
    },

    emotion: {
        empathy: 'emotion_empathy',
        enthusiasm: 'emotion_enthusiasm',
        urgency: 'emotion_urgency',
        emoji: 'emotion_emoji',
    },

    formality: {
        increase: 'formality_increase',
        decrease: 'formality_decrease'
    },
    holidays: {
        black_friday: 'holiday_black_friday',
        chinese_new_year: 'holiday_chinese_new_year',
        cinco: 'holiday_cinco',
        christmas: 'holiday_christmas',
        diwali: 'holiday_diwali',
        easter: 'holiday_easter',
        eid_adha: 'holiday_eid_adha',
        eid_fitr: 'holiday_eid_fitr',
        fathers: 'holiday_fathers',
        halloween: 'holiday_halloween',
        hanukkah: 'holiday_hanukkah',
        independence: 'holiday_independence',
        kwanzaa: 'holiday_kwanzaa',
        labor: 'holiday_labor',
        midautumn: 'holiday_midautumn',
        mothers: 'holiday_mothers',
        newyear: 'holiday_newyear',
        oktoberfest: 'holiday_oktoberfest',
        stpatrick: 'holiday_stpatrick',
        thanksgiving: 'holiday_thanksgiving',
        valentine: 'holiday_valentine'
    },
    language: {
        simplify: 'language_simplify',
        professional: 'language_professional'
    },

    length: {
        shorten: 'length_shorten',
        expand: 'length_expand'
    },

    personalize: {
        reference: 'personalize_reference'
    },

    perspective: {
        customer: 'perspective_customer',
        company: 'perspective_company'
    },

    persuasion: {
        more: 'persuasion_more',
        less: 'persuasion_less'
    },

    structure: {
        bullets: 'structure_bullets',
        headings: 'structure_headings'
    }
};




class EditEmailPromptGenerator {
    static getPrompt(actionKey, contextEmailType) {
        // Validate inputs
        if (!actionKey) {
            return '[Error] Action key is required.';
        }

        // Construct the base prompt
        let basePrompt = '';

        // Helper function to access nested action types
        const findActionType = (key) => {
            for (const [category, actions] of Object.entries(EditEmailActionTypes)) {
                if (Object.values(actions).includes(key)) {
                    return key;
                }
            }
            return null;
        };

        // Get the action type
        const actionType = findActionType(actionKey);
        if (!actionType) {
            return `[Error] No prompt found for action key: ${actionKey}`;
        }

        const emailContext = apiContextEmailTypes.getPromptValue(contextEmailType);

        // Define prompts map
        const promptsMap = {
            // Clarity Actions
            [EditEmailActionTypes.clarity.simplify]:
                `Please revise the following ${emailContext} email to optimize clarity by simplifying complex sentences and making the content easier to understand.`,
            [EditEmailActionTypes.clarity.technical]:
                `Please revise the following ${emailContext} email to include more technical details appropriate for a knowledgeable audience.`,

            // Culture Actions
            [EditEmailActionTypes.culture.inclusive]:
                `Please revise the following ${emailContext} email to use inclusive language, avoiding idioms or references that may not be universally understood.`,
            [EditEmailActionTypes.culture.events]:
                `Please revise the following ${emailContext} email to respectfully acknowledge relevant cultural events or holidays.`,

            [EditEmailActionTypes.custom.custom]:
                `Please revise the following ${emailContext} email to `,

            // Emotion Actions
            [EditEmailActionTypes.emotion.empathy]:
                `Please revise the following ${emailContext} email to adopt a more empathetic tone, showing understanding and concern for the customer's needs.`,
            [EditEmailActionTypes.emotion.enthusiasm]:
                `Please revise the following ${emailContext} email to express enthusiasm, conveying excitement about the products or services.`,
            [EditEmailActionTypes.emotion.urgency]:
                `Please revise the following ${emailContext} email to create a sense of urgency, encouraging prompt action with time-sensitive language.`,
            [EditEmailActionTypes.emotion.emoji]:
                `Please revise the following ${emailContext} email to add some emojis.`,

            // Formality Actions
            [EditEmailActionTypes.formality.increase]:
                `Please revise the following ${emailContext} email to increase formality, using professional language and formal greetings.`,
            [EditEmailActionTypes.formality.decrease]:
                `Please revise the following ${emailContext} email to decrease formality, adopting a friendly and casual tone.`,

            // Language Actions
            [EditEmailActionTypes.language.simplify]:
                `Please revise the following ${emailContext} email to simplify the language, using clear and straightforward terms for easy understanding.`,
            [EditEmailActionTypes.language.professional]:
                `Please revise the following ${emailContext} email to use professional terminology, incorporating industry-specific terms to showcase expertise.`,

            // Length Actions
            [EditEmailActionTypes.length.shorten]:
                `Please revise the following ${emailContext} email to shorten it, focusing on key points and making it more concise.`,
            [EditEmailActionTypes.length.expand]:
                `Please revise the following ${emailContext} email to expand it, providing additional details and explanations where appropriate.`,

            // Personalize Actions
            [EditEmailActionTypes.personalize.reference]:
                `Please revise the following ${emailContext} email to personalize it by referencing past interactions or communications with the customer.`,

            // Perspective Actions
            [EditEmailActionTypes.perspective.customer]:
                `Please revise the following ${emailContext} email to adopt a customer-centric perspective, focusing on benefits and solutions relevant to the customer.`,
            [EditEmailActionTypes.perspective.company]:
                `Please revise the following ${emailContext} email to adopt a company-centric perspective, highlighting the company's strengths and expertise.`,

            // Persuasion Actions
            [EditEmailActionTypes.persuasion.more]:
                `Please revise the following ${emailContext} email to enhance its persuasiveness by adding compelling language and strong calls-to-action.`,
            [EditEmailActionTypes.persuasion.less]:
                `Please revise the following ${emailContext} email to reduce persuasive elements, adopting a more informational tone.`,

            // Structure Actions
            [EditEmailActionTypes.structure.bullets]:
                `Please revise the following ${emailContext} email to improve its structure by incorporating bullet points for key information to enhance readability.`,
            [EditEmailActionTypes.structure.headings]:
                `Please revise the following ${emailContext} email to improve its structure by adding headings and subheadings to break content into clear sections.`,

            // Holiday-specific prompts
            [EditEmailActionTypes.holidays.newyear]:
                `Please revise the following ${emailContext} email to incorporate New Year's Day themes, focusing on new beginnings, resolutions, and fresh starts.`,
            [EditEmailActionTypes.holidays.valentine]:
                `Please revise the following ${emailContext} email to include Valentine's Day themes, emphasizing appreciation and warm wishes.`,
            [EditEmailActionTypes.holidays.stpatrick]:
                `Please revise the following ${emailContext} email to incorporate St. Patrick's Day themes and festive Irish elements.`,
            [EditEmailActionTypes.holidays.easter]:
                `Please revise the following ${emailContext} email to include Easter themes, focusing on renewal and spring celebrations.`,
            [EditEmailActionTypes.holidays.mothers]:
                `Please revise the following ${emailContext} email to incorporate Mother's Day themes, expressing gratitude and appreciation.`,
            [EditEmailActionTypes.holidays.fathers]:
                `Please revise the following ${emailContext} email to incorporate Father's Day themes, expressing gratitude and appreciation.`,
            [EditEmailActionTypes.holidays.independence]:
                `Please revise the following ${emailContext} email to include Independence Day (US) themes, emphasizing celebration and patriotic elements.`,
            [EditEmailActionTypes.holidays.halloween]:
                `Please revise the following ${emailContext} email to incorporate Halloween themes with a fun and festive tone.`,
            [EditEmailActionTypes.holidays.thanksgiving]:
                `Please revise the following ${emailContext} email to include Thanksgiving themes, expressing gratitude and appreciation.`,
            [EditEmailActionTypes.holidays.black_friday]: 
                `Please revise the following ${emailContext} email to incorporate Black Friday themes, emphasizing exclusive deals, limited-time offers, and creating excitement about special savings. Include urgent call-to-actions and highlight the biggest discounts while maintaining professionalism.`,
             [EditEmailActionTypes.holidays.christmas]:
                `Please revise the following ${emailContext} email to incorporate Christmas themes, focusing on joy, giving, and seasonal celebration.`,
            [EditEmailActionTypes.holidays.hanukkah]:
                `Please revise the following ${emailContext} email to include Hanukkah themes, respecting the Festival of Lights traditions.`,
            [EditEmailActionTypes.holidays.kwanzaa]:
                `Please revise the following ${emailContext} email to incorporate Kwanzaa themes, celebrating African-American heritage.`,
            [EditEmailActionTypes.holidays.chinese_new_year]:
                `Please revise the following ${emailContext} email to include Lunar New Year themes, incorporating traditional celebration elements.`,
            [EditEmailActionTypes.holidays.diwali]:
                `Please revise the following ${emailContext} email to incorporate Diwali themes, celebrating the Festival of Lights.`,
            [EditEmailActionTypes.holidays.eid_fitr]:
                `Please revise the following ${emailContext} email to include Eid al-Fitr themes, celebrating the end of Ramadan.`,
            [EditEmailActionTypes.holidays.eid_adha]:
                `Please revise the following ${emailContext} email to incorporate Eid al-Adha themes with appropriate celebratory elements.`,
            [EditEmailActionTypes.holidays.labor]:
                `Please revise the following ${emailContext} email to include Labor Day themes, acknowledging worker contributions.`,
            [EditEmailActionTypes.holidays.oktoberfest]:
                `Please revise the following ${emailContext} email to incorporate Oktoberfest themes, celebrating German culture and tradition.`,
            [EditEmailActionTypes.holidays.cinco]:
                `Please revise the following ${emailContext} email to include Cinco de Mayo themes, celebrating Mexican heritage and culture.`,
            [EditEmailActionTypes.holidays.midautumn]:
                `Please revise the following ${emailContext} email to incorporate Mid-Autumn Festival themes, celebrating harvest and unity.`,
        };

        return promptsMap[actionType];

    }
}