import * as React from 'react'
import { AccentComboUnbound } from '../unbound/AccentComboUnbound';
import { getProduct, getUserProductList } from '../../services/ProductService';
import { ProductItemTemplate, ProductItemVersion } from './ProductItemTemplate';
import { accentUtils } from '../../services/HelperService';





export const ProductCombo = React.memo(({
    required,
    defaultProductID,
    defaultProductVersionID,
    onChange,
    label,
    disabled,
    filter_businessUnitID,
    filter_supplierID,
    filter_showInActive,
    filter_showCannotSell,
    filter_showDraft,
    filter_showExclusions,
    filter_showMultiProducts,
    filter_showAllMemberProducts
}) => {

    if (accentUtils.isNull(defaultProductVersionID)) {
        defaultProductVersionID = accentUtils.getEmptyGuid();
    }

    const [selectedValue, setSelectedValue] = React.useState(`${defaultProductID}_${defaultProductVersionID ?? null}`);
    const [selectedProductID, setSelectedProductID] = React.useState(defaultProductID ?? null);
    const [selectedProductVersionID, setSelectedVersionProductID] = React.useState(defaultProductVersionID ?? null);

    const products = React.useMemo(() => {

        const buID = filter_businessUnitID ? filter_businessUnitID : null;
        const supplier = filter_supplierID ? filter_supplierID : null;
        

        return getUserProductList({
            includeProductID: defaultProductID,
            includeProductVersionID: defaultProductVersionID,
            includeCannotSell: filter_showCannotSell,
            includeDraft: filter_showDraft,
            includeExclusions: filter_showExclusions,
            includeInActive: filter_showInActive,
            includeMultiProducts: filter_showMultiProducts,
            includeAllMemberProducts: filter_showAllMemberProducts,
            filterBySupplierID: supplier,
            exclusionBusinessUnitID: buID,
        }).map(v => ({ ...v, product_version_id : `${v.ID}_${v.VersionID ?? null}`}));


    }, [
        filter_businessUnitID,
        filter_supplierID,
        filter_showInActive,
        filter_showCannotSell,
        filter_showDraft,
        filter_showExclusions,
        filter_showMultiProducts,
        filter_showAllMemberProducts,
        defaultProductID,
        defaultProductVersionID
    ]);


    const handleOnChange = React.useCallback(e => {


        setSelectedValue(e);

        const productID = accentUtils.isEmpty(e) ? null : e.split('_')[0];
        let versionID = accentUtils.isEmpty(e) ? null : e.split('_')[1];


        if (versionID === "null") {
            versionID = null;
        }


        setSelectedProductID(productID);
        setSelectedVersionProductID(versionID);

        if (onChange) {



            const product = accentUtils.isEmpty(productID) ? null : getProduct(productID, versionID);

            onChange(product);
        }


    }, [setSelectedValue, setSelectedProductID, setSelectedVersionProductID, onChange]);


    
    const product = accentUtils.isEmpty(selectedProductID) ? null : getProduct(selectedProductID, selectedProductVersionID);

    const showVersions = !(product?.IsActiveVersion ?? true)

    const itemRender = showVersions ? e => <div className="d-flex" style={{ width: '100%' }}>
        <div>{e}</div>
        <div style={{ paddingTop: "7px" }}><ProductItemVersion version={product?.Version} productID={product?.ID} versionID={product?.VersionID }  /></div>
    </div> : null;


    return <AccentComboUnbound
        required={required}
        displayField="Product"
        valueField="product_version_id"
        disabled={disabled}
        defaultValue={selectedValue}
        onChange={handleOnChange}
        label={label}
        data={products}
        itemRender={ProductItemTemplate}
        valueRender={itemRender}
    />

});