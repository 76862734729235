import React, { useState, useEffect } from 'react';
import { Alert, Collapse } from 'reactstrap';

const AIWarningAlert = () => {
    // Read showWarningDefault from local storage or set to true if not found
    const storedShowWarning = localStorage.getItem('AccentAI.showWarningDefault');
    const [isOpen, setIsOpen] = useState(storedShowWarning === null ? true : JSON.parse(storedShowWarning));

    useEffect(() => {
        // Store the value in local storage whenever it changes
        localStorage.setItem('AccentAI.showWarningDefault', JSON.stringify(isOpen));
    }, [isOpen]);

    return (
        <Alert color="danger" className="p-3 my-2 small">
            <div className="d-flex justify-content-between align-items-start">
                <div>
                    <h5 className="alert-heading mb-1">
                        Important Notice: AI-Powered Quick Actions
                    </h5>
                    <p className="mb-1">
                        Please review all AI-modified content carefully before sending.{' '}
                        <button
                            className="btn btn-link btn-sm text-danger p-0 text-decoration-none"
                            onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
                        >
                            {isOpen ? 'Show less' : 'Show details'}
                        </button>
                    </p>
                </div>
            </div>

            <Collapse isOpen={isOpen}>
                <div className="mt-2">
                    <p className="mb-1">
                        By using this feature, you acknowledge and accept that:
                    </p>
                    <ul className="mb-2 ps-4">
                        <li>You are responsible for reviewing and approving all content generated or modified by the AI.</li>
                        <li>The feature is provided "as is" without warranties of any kind.</li>
                        <li>We are not liable for any errors, omissions, or unintended consequences resulting from the use of this feature.</li>
                    </ul>
                    <p className="fw-bold mb-0">
                        Use this feature at your own risk.
                    </p>
                </div>
            </Collapse>
        </Alert>
    );
};

export default AIWarningAlert;
