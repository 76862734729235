import pako from 'pako';

import { downloadObject, queryNoTracking } from './DataService';
import { accentUserLang, accentUtils, from, t } from './HelperService'
import { getUser } from './UserService';
import { selectProduct } from '../dialogs/ProductSelectionDlg';
import { query_UnitTypes } from '../../PermanentQueries';



const query_ProductList = queryNoTracking("ProductList");
const query_Product_BUExclusions = queryNoTracking("Product_BUExclusions");


const toFixedPicklist = (picklist) => {
    picklist.values = Object.keys(picklist).map(k => picklist[k]);
    picklist.getPicklistSource = () => picklist.values.map(v => { return { ID: v, Description: t(v) }; });
};

export const optionDisplayTypes = {
    Picklist: "Picklist",
    Numeric: "Numeric",
    Decimal: "Decimal",
    Checkbox: "Checkbox",
    Text: "Text",
    LinearMetreCuts: "Linear Metre Cuts",
    Fabric: "Fabric",
    Fabric2Line: "Fabric 2 Line",
    PanelPattern: "Panel Pattern",
};

toFixedPicklist(optionDisplayTypes);

let products = [];
let productActiveVersions = {};
window.productList = products;
let productBUExclusions = [];
let unitTypes = [];

let productDictionary = null;

export function configFrom(collection) {
    return window.System.Linq.Enumerable.from(collection);
}

export function getUnitTypes() {
    return unitTypes;
}

export async function refreshProductExclusionList() {
    productBUExclusions = await query_Product_BUExclusions.getAll();
    unitTypes = await query_UnitTypes.getAll();
}

function setActiveVersions() {

    products.filter(p => p.IsActiveVersion === true).map(p => {
        productActiveVersions[p.ID.toUpperCase()] = p.VersionID;
    });

}
function getProductCacheKey(user) {
    return `productList_${user.ClientID}`;
}

export function loadLocallyCachedProducts(user) {

    const localStoredProducts = localStorage.getItem(getProductCacheKey(user));


    if (accentUtils.isEmpty(products)) {
        products = accentUtils.isNull(localStoredProducts) ? [] : JSON.parse(localStoredProducts);
        window.productList = products;
        setActiveVersions();
    }
    

}

export async function refreshProducts(clearServerCache) {

    productDictionary = null;

    products = await query_ProductList.getAll({ clearCache: clearServerCache });

    const user = getUser();

    window.productList = products;
    setActiveVersions();

    window.setTimeout(() => {
        localStorage.setItem(getProductCacheKey(user), JSON.stringify(products));
    }, 200);

    return products;
}

export function getProduct(id, versionID) {



    const idUpper = id?.toUpperCase() ?? null;

    if (accentUtils.isNull(versionID)) {
        versionID = productActiveVersions[idUpper];
    }


    if (accentUtils.isNull(idUpper)) return null;

    const versionUpperID = versionID?.toUpperCase() ?? null;



    if (accentUtils.isNull(productDictionary)) {

        productDictionary = {};

        const products = getProducts();

        for (var i = 0; i <  products.length; i++) {

            const currProduct = products[i];
            const currProductID = currProduct.ID.toUpperCase();

            if (!productDictionary[currProductID]) {
                productDictionary[currProductID] = [];
            }

            productDictionary[currProductID].push(currProduct);

        }

    }


    const productVersions = productDictionary[idUpper] ?? [];


    if (versionUpperID) {
        return from(productVersions).where(v => (v.VersionID ?? accentUtils.getEmptyGuid()).toUpperCase() === versionUpperID).firstOrDefault();
    } else {
        return from(productVersions).orderBy(v => v.IsActiveVersion).thenBy(v => v.IsDraft).firstOrDefault();
    }

    
}

export function getProducts() {
    return [...products];
}



export function productVersionDisplayText(product) {

    if (product.IsDraft) return "DRAFT";

    return `v${product.Version}`;

}
export function productVersionSort(product) {

    if (product.IsDraft) return -1;

    const num = Number(product.Version);

    if (isNaN(num)) {
        return 0;
    }

    return 99999999999 - num;
}



export function allowMemberProductSeperation(productID, memberConfigID) {
    return from(getProduct(productID)?.MemberProducts ?? []).any(m => (!m.Required && m.CanBeSeparated) && m.ID.toUpperCase() === memberConfigID?.toUpperCase());
}

export const ConfigNotifications = {
    SizeChanging: window.InsyteConfig.InsyteConfigEvents.SizeChanging,
    SizeChangeOccurred: window.InsyteConfig.InsyteConfigEvents.SizeChangeOccurred
};


export async function getProductConfig(lineEntity, olos, isReadOnly, isQuote, onSave, onChanged, ignoreSizes, companyInfo, onNewOrderLineOption) {

    const res = await loadProductConfig(lineEntity, olos, isReadOnly, isQuote, onSave, onChanged, ignoreSizes, companyInfo, onNewOrderLineOption);

    return res;

}

let multiConifigFakeLineID = -999999;
export async function getMultiConfigConfig(productID, versionID) {

    return await getProductConfig(
        { ID: multiConifigFakeLineID--, ProductID: productID, MultiProductVersionID: versionID },
        "[]",
        true,
        true,
        () => { }, //save olos
        () => { },
        true,
        null,
        null);

}

function loadProductConfig(lineEntity, olos, isReadOnly, isQuote, onSave, onChanged, ignoreSizes, companyInfo, onNewOrderLineOption) {

    window.InsyteConfig.DateToStringProvider.Local = accentUserLang;


    return new Promise(p => {

        window.InsyteHTML.Apps.Configurator.Configurator_App.GetConfigModel(
            pID => {

                const product = getProduct(pID);

                const isMultiConfig = product.IsMultiConfig;

                const versionID = (isMultiConfig ? lineEntity.MultiProductVersionID : lineEntity.ProductVersionID);


                return loadProductModel(pID, versionID);
            },                    
            res => {
                p(res);
            },
            lineEntity,
            olos,
            isReadOnly,
            isQuote,
            onSave,
            onChanged,
            ignoreSizes,
            companyInfo,
            onNewOrderLineOption
        )

    });


}


//export function reloadConfigurator(id, lineEntity, olos, isReadOnly, isQuote, onSave, onChanged, onLoaded, ignoreSizes, companyInfo) {

//    window.InsyteHTML.Apps.Configurator.Configurator_App.ReloadConfigTRL(
//        lineEntity,
//        olos,
//        isReadOnly,
//        isQuote,
//        onSave,
//        onChanged,
//        onLoaded,
//        ignoreSizes,
//        companyInfo,
//        id
//    )

//}



export function createDiscountNodes(products) {
    return window.InsyteProduct.InsyteWeb.DiscountTreeHelper.CreateDiscountNodes(products);
}



export function InsyteHtml() {
    var tt = 4;
}

const modelCache = {};


function getModelCacheKey(productID, versionID) {
    return `${productID}_${versionID}`;

}
export function removeFromModelCache(productID, versionID) {
    modelCache[getModelCacheKey(productID, versionID)] = null;
}


async function downloadProductJson(product) {
    const data = await makeCorsRequest(product.ModelURI, product.ModelIsCompressed);


    const output = product.ModelIsCompressed ? pako.inflate(data) : data;

    const file = new TextDecoder('utf-8').decode(output);

    return file;
}


export async function loadProductJson(productID, versionID) {

    if (accentUtils.isNull(versionID)) {
        versionID = productActiveVersions[productID.toUpperCase()];
    }

    var product = getProduct(productID, versionID);


    if (!accentUtils.isNull(product)) {


        const file = await downloadProductJson(product);


        return JSON.parse(file);

    }

    return null;
}

export async function loadProductModel(productID, versionID) {


    if (accentUtils.isNull(versionID)) {
        versionID = productActiveVersions[productID.toUpperCase()];
    }

    const key = getModelCacheKey(productID, versionID);


    if (!accentUtils.isNull(modelCache[key])) return modelCache[key];

    //const prodList = (products.length > 0) ? products : await refreshProducts();

    var product = getProduct(productID, versionID);

    if (!accentUtils.isNull(product)) {


        const file = await downloadProductJson(product);

       
        const model = JSON.parse(file);

        window.InsyteHTML.Apps.Configurator.Configurator_App.Init();

        const dataModel = window.InsyteHTML.Apps.Configurator.RTL.ModelLoader.LoadDataModel(model);

        modelCache[key] = dataModel;

        return dataModel;

    }

    return null;


}

export async function downloadProduct(productID, productVerionID) {


    const product = getProduct(productID, productVerionID);

    if (!accentUtils.isEmpty(product.ModelURI)) {

        const productModel = await downloadProductJson(product);
        await downloadObject(productModel, `Model_${product.ProductName}.json`);

    }


    const definition = await queryNoTracking("Product_Definition").getFirstOrDefault({ productID: product.IntID, productVerionID : productVerionID});

    await downloadObject(definition.ProductDefinition, `Definition_${product.ProductName}.json`);

}


export function getUserProductList({
    includeProductID,
    includeProductVersionID,
    includeInActive,
    includeCannotSell,
    includeDraft,
    includeExclusions,
    includeAllMemberProducts,
    includeMultiProducts,
    filterBySupplierID,
    exclusionBusinessUnitID
}) {

    if (accentUtils.isNull(includeProductID)) {
        includeProductID = '00000000-0000-0000-0000-000000000000';
    }

    if (includeMultiProducts === undefined) {
        includeMultiProducts = true;
    }


    var exclusions = (accentUtils.isEmpty(exclusionBusinessUnitID) || includeExclusions) ? [] : from(productBUExclusions).where(ex => ex.BusinessUnitID == exclusionBusinessUnitID).toArray();

    var additionalInclusions = includeAllMemberProducts
        ? from(products)
            .where(p => p.IsMultiConfig && p.Active && p.CanSell)
            .selectMany(p => from(p.MemberProducts).select(mp => mp.MemberProductID.toUpperCase()))
            .toArray()
            : [];


    var res =
        from(products)
            .select(function (p) {
                return {
                    ID: p.ID,
                    Active: p.Active,
                    Supplier: p.Supplier,
                    SupplierKey: p.SupplierKey,
                    ResellerMarketplaceKeys: p.ResellerMarketplaceKeys,
                    Product: p.ProductName,
                    ProductCode: p.ProductCode,
                    CanSell: p.CanSell,
                    IsMarketplaceProduct: p.IsMarketplaceProduct,
                    ModelURI: p.ModelURI,
                    Version: p.Version,
                    IsDraft: p.IsDraft,
                    VersionID: p.VersionID,
                    IsActiveVersion: p.IsActiveVersion,
                    IsMultiConfig: p.IsMultiConfig
                };
            })
            .where(p => {

                const upperID = p.ID.toUpperCase();

                const mustIncludeProduct = includeProductID && includeProductID.toUpperCase() === upperID;
                const mustIncludeProductVersion = p.VersionID && includeProductVersionID && includeProductVersionID.toUpperCase() === p.VersionID.toUpperCase();

                if (mustIncludeProduct && mustIncludeProductVersion) return true;


                if (additionalInclusions.length > 0) {

                    if (p.IsActiveVersion && additionalInclusions.includes(upperID)) {
                        return true;
                    }

                }


                if (!includeDraft && p.IsDraft) return false;

                if (!accentUtils.isEmpty(filterBySupplierID) && p.SupplierKey.toUpperCase() !== filterBySupplierID.toUpperCase()) return false;

                if (!includeInActive && !p.Active) return false;

                if (!includeCannotSell && !p.CanSell) return false;

                if (!includeExclusions && exclusions.length > 0) {

                    if (from(exclusions).any(ex => upperID === ex.ProductID.toUpperCase())) {
                        return false;
                    }
                }

                if (!includeMultiProducts && p.IsMultiConfig) {
                    return false;
                }


                return p.IsActiveVersion;
            })
            .orderBy(p => p.Product)
            .thenBy(p => p.Supplier)
            .toArray();

    res.unshift({
        ID: '00000000-0000-0000-0000-000000000000',
        Product: "",
    });

    return res;
}



// Create the XHR object.
function createNonCORSRequest(method, url) {
    var xhr = new XMLHttpRequest();
    xhr.responseType = "arraybuffer";
    xhr.open(method, url);
    return xhr;
}

// Create the XHR object.
function createCORSRequest(method, url) {
    var xhr = new XMLHttpRequest();
    xhr.responseType = "arraybuffer";
    if ("withCredentials" in xhr) {
        // XHR for Chrome/Firefox/Opera/Safari.
        xhr.open(method, url, true);
    } else if (typeof window.XDomainRequest != "undefined") {
        // XDomainRequest for IE.
        xhr = new window.XDomainRequest();
        xhr.open(method, url);
    } else {
        // CORS not supported.
        xhr = null;
    }
    return xhr;
}

function makeCorsRequest(url, useCors) {

    return new Promise(p => {

        var xhr = (useCors) ? createCORSRequest('GET', url) : createNonCORSRequest('GET', url);
        if (useCors && !xhr) {
            alert('CORS not supported');
            p(null);
            return;
        }

        // Response handlers.
        xhr.onload = function () {

            var arrayBuffer = xhr.response;

            // if you want to access the bytes:
            var byteArray = new Uint8Array(arrayBuffer);
            p(byteArray);
        };

        xhr.onerror = function () {
            alert('Woops, there was an error making the request.');
            p(null);
        };

        xhr.send();


    });


    
}

export async function selectAlterProduct(existingAlterToExclude) {


    const multiConfigIDs = getProducts().filter(p => p.IsMultiConfig).map(p=>p.ID);

    if (!accentUtils.isNull(existingAlterToExclude)) {
        multiConfigIDs.push(existingAlterToExclude);
    }

    const res = await selectProduct({ selectMultiple: false, includeInActive: true, includeCannotSell: true, includeExclusions: true, excludeIDs: multiConfigIDs });

    return res?.productID;

}
