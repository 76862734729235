import 'material-icons/iconfont/material-icons.css';
import './kendo-theme.scss';
import "@progress/kendo-font-icons/dist/index.css";
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import AuthenticatedApp from './components/api-authorization/AuthenticatedApp';
import { AccentRouter } from './components/app-framework/AccentRouter';
import { RecoilRoot } from 'recoil';
import authService from './components/api-authorization/AuthorizeService';
import config from 'devextreme/core/config';
import { devExpressLicenseKey } from './devextreme-license';

config({ licenseKey: devExpressLicenseKey });



const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const history = createBrowserHistory();

window.accentRouter = history;


window.loadTimeoutID = null;

function startReload() {
    window.loadTimeoutID = setTimeout(function () {
        window.location.reload();
    }, 20000); // 20 seconds
}


function loadApp() {
    //Warning StrictMode cause double render in DEV


    const root = createRoot(rootElement);

    root.render(
        <RecoilRoot>
            <AccentRouter history={history}>
                <React.Suspense fallback={<div className="center-screen app-loading-msg">Please wait...</div>} >
                    <AuthenticatedApp />
                </React.Suspense  >
            </AccentRouter>
        </RecoilRoot>);


    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.unregister();

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();



}

async function signoutAndRedirect(redirecturi) {
    await authService.Initialize();
    if (authService.isAuthenticated) {
        await authService.signOutWithRedirect(redirecturi);
    }
}

async function completeSignup() {
    await authService.Initialize();
    await authService.completeSignup();

    window.location.href = "/";
}

async function completeSignin() {
    await authService.Initialize();
    await authService.completeSignin();

}

function setDocumentHtml(returnUrl) {
    document.documentElement.innerHTML = '<html><head><style>a {text-decoration:none; font-size: 24px; color:white} .msgtext {padding-top:100px; font-size:20px} .clickarea {border-radius:5px; background-color:blue; width:350px; padding:10px; text-align:center;}</style></head><body><center><div class="msgtext">You have been logged out of Insyte Web.</div><br /><div class="clickarea"><a href="' + returnUrl + '">Click here to log in</a></div></center></body></html>';
}


startReload();

if (window.location.pathname.startsWith("/signoutandredirect")) {
    signoutAndRedirect(window.location.href.replace("/signoutandredirect", ""));
} else if (window.location.pathname.startsWith("/authentication/login-callback")) {
    console.log('signup callback');
    completeSignup();
} else if (window.location.pathname.startsWith("/signin")) {
    console.log('signin callback');
    completeSignin();
} else if (window.location.pathname.startsWith("/logout")) {
    var urlParams = new URLSearchParams(window.location.search);
    var returnUrl = urlParams.get('returnUrl');
    setDocumentHtml(returnUrl===null?'/':returnUrl);
} else {



    if (window.location.pathname.endsWith("/NEW")) {

        var urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get('newEntity')) {
            window.location.href = "/"
        } else {
            loadApp();
        }

        

    } else {
        loadApp();
    }
}