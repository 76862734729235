import { accentUtils, from } from "./HelperService";
import { getUser } from "./UserService";




const defaultRequiredFields = {
    addressLines: true,
    city: true,
    state: true,
    postcode: true,
    country: true
};

const requiredAddressFields = {
    "US": { ...defaultRequiredFields },
    "GB": { ...defaultRequiredFields },
    "IE": { ...defaultRequiredFields, postcode : false },
    "CA": { ...defaultRequiredFields },
    "ZA": { ...defaultRequiredFields },
    "AU": { ...defaultRequiredFields },
    "NZ": { ...defaultRequiredFields }
};



const defaultMapping = {
    AddressLines: [
        {
            name: 'premise',
            value: 'short_name',
            format: '{0}, '
        },
        {
            name: 'subpremise',
            value: 'short_name',
            format: '{0}/'
        },
        {
            name: 'street_number',
            value: 'short_name',
            format: '{0} '
        },
        {
            name: 'route',
            value: 'short_name',
            format: '{0} '
        },
        {
            name: 'neighborhood',
            value: 'short_name',
            format: '{0}'
        },
    ],
    City: [
        {
            name: 'locality',
            value: 'long_name',
        },
        {
            name: 'sublocality',
            value: 'long_name',
        },
        {
            name: 'postal_town',
            value: 'long_name',
        },
    ],
    State: [
        {
            name: 'administrative_area_level_1',
            value: 'short_name',
        },
        {
            name: 'administrative_area_level_2',
            value: 'long_name',
        },
    ],
    Postcode: [
        {
            name: 'postal_code',
            value: 'short_name',
        },
    ],
    Country: [
        {
            name: 'country',
            value: 'long_name',
        },
    ]
};

const IrelandMapping = {
    ...defaultMapping, State: [
        {
            name: 'administrative_area_level_1',
            value: 'long_name',
        },
        {
            name: 'administrative_area_level_2',
            value: 'long_name',
        },
    ]
};

const USMapping = {
    ...defaultMapping,
    AddressLines: [
        {
            name: 'street_number',
            value: 'short_name',
            format: '{0} '
        },
        {
            name: 'route',
            value: 'short_name',
            format: '{0}, '
        },
        {
            name: 'subpremise',
            value: 'short_name',
            format: 'Apt {0}, '
        },
        {
            name: 'premise',
            value: 'short_name',
            format: '{0}, '
        },
        {
            name: 'neighborhood',
            value: 'short_name',
            format: '{0}'
        },
    ]
};

const SouthAfricaMapping = {
    ...defaultMapping,
    AddressLines: [
        {
            name: 'street_number',
            value: 'short_name',
            format: '{0} '
        },
        {
            name: 'route',
            value: 'short_name',
            format: '{0}, '
        },
        {
            name: 'subpremise',
            value: 'short_name',
            format: 'Unit {0}, '
        },
        {
            name: 'premise',
            value: 'short_name',
            format: '{0}, '
        },
        {
            name: 'sublocality_level_1',
            value: 'short_name',
            format: '{0}, '
        },
        {
            name: 'neighborhood',
            value: 'short_name',
            format: '{0}'
        },
    ]
};


const IndiaMapping = {
    ...defaultMapping,
    AddressLines: [
        {
            name: 'street_number',
            value: 'long_name',
            format: '{0}, '
        },
        {
            name: 'route',
            value: 'long_name',
            format: '{0}, '
        },
        {
            name: 'point_of_interest',
            value: 'long_name',
            format: 'near {0}, '
        },
        {
            name: 'neighborhood',
            value: 'long_name',
            format: '{0}, '
        },
        {
            name: 'sublocality_level_3',
            value: 'long_name',
            format: '{0}, '
        },
        {
            name: 'sublocality_level_2',
            value: 'long_name',
            format: '{0}, '
        },
        {
            name: 'sublocality_level_1',
            value: 'long_name',
            format: '{0}'
        },
    ],
    City: [
        {
            name: 'locality',
            value: 'long_name',
        },
    ],
    State: [
        {
            name: 'administrative_area_level_1',
            value: 'long_name',
        },
    ],
    Postcode: [
        {
            name: 'postal_code',
            value: 'long_name',
        },
    ],
    Country: [
        {
            name: 'country',
            value: 'long_name',
        },
    ]
};

// Address model
const defaultMappings = {
    "US": { ...USMapping },
    "GB": { ...defaultMapping },
    "IE": IrelandMapping,
    "CA": { ...defaultMapping },
    "ZA": { ...SouthAfricaMapping },
    "AU": { ...defaultMapping },
    "NZ": { ...defaultMapping },
    "IN": { ...IndiaMapping },
};


function customParseStreetNumber(address) {
    if (accentUtils.isEmpty(address)) return {
        unitNumber: null,
        streetNumber: null
    };

    // First, try to match the unit/street number format
    const unitStreetRegex = /(\d+)\/(\d+)/;
    const unitStreetMatch = address.match(unitStreetRegex);

    if (unitStreetMatch) {
        return {
            unitNumber: unitStreetMatch[1],
            streetNumber: unitStreetMatch[2]
        };
    }

    // If that doesn't match, try to find the first number in the address
    const generalNumberRegex = /^(\d+)/;
    const generalNumberMatch = address.match(generalNumberRegex);

    if (generalNumberMatch) {
        return {
            unitNumber: null,
            streetNumber: generalNumberMatch[1]
        };
    }

    // If no number is found, return null for both
    return {
        unitNumber: null,
        streetNumber: null
    };
}


function getAddressComponent(place, type) {
    return place.address_components.find(c => c.types.includes(type));
}


function getComponentValue(component, mappingValue) {
    if (accentUtils.isNull(component)) return null;
    return component[mappingValue.value];
}

export function getRequiredAddressFields(country) {
    const countryCode = getCountryCode(country);
    return requiredAddressFields[countryCode];
}


export function getCountryCode(country) {

    if (accentUtils.isEmpty(country)) {
        country = getUser()?.DefaultSettings?.Country;
    }


    var res = 'AU';

    if (!accentUtils.isEmpty(country)) {

        if (country.toUpperCase() === "NEW ZEALAND") {
            res = 'NZ';
        } else if (country.toUpperCase() === "CANADA") {
            res = 'CA';
        } else if (country.toUpperCase() === "SOUTH AFRICA") {
            res = 'ZA';
        } else if (country.toUpperCase() === "UNITED KINGDOM") {
            res = 'GB';
        } else if (country.toUpperCase() === "UNITED STATES") {
            res = 'US';
        } else if (country.toUpperCase() === "IRELAND") {
            res = 'IE';
        } else if (country.toUpperCase() === "INDIA") {
            res = 'IN';
        }

    }

    return res;

}



export function getAddressFromPlace(place, addressText) {
    const country = getAddressComponent(place, "country")["long_name"];
    const countryCode = getCountryCode(country);

    const mapping = defaultMappings[countryCode];
    let addressModel = {
        AddressLines: '',
        City: '',
        State: '',
        Postcode: '',
        Country: '',
        PosLong: place.geometry.location.lng(),
        PosLat: place.geometry.location.lat()
    };

    const addressNumbers = customParseStreetNumber(addressText);

    addressModel.Country = from(mapping.Country).select(c => getComponentValue(getAddressComponent(place, c.name), c)).where(v => !accentUtils.isEmpty(v)).firstOrDefault();
    addressModel.State = from(mapping.State).select(c => getComponentValue(getAddressComponent(place, c.name), c)).where(v => !accentUtils.isEmpty(v) && v !== addressModel.Country).firstOrDefault();
    addressModel.City = from(mapping.City).select(c => getComponentValue(getAddressComponent(place, c.name), c)).where(v => !accentUtils.isEmpty(v) && v !== addressModel.State && v !== addressModel.Country).firstOrDefault();
    addressModel.Postcode = from(mapping.Postcode).select(c => getComponentValue(getAddressComponent(place, c.name), c)).where(v => !accentUtils.isEmpty(v)).firstOrDefault();

    addressModel.AddressLines = from(mapping.AddressLines).select(c => {
        const isUnit = c.name === "subpremise";
        const hasCustomParseUnit = !accentUtils.isEmpty(addressNumbers.unitNumber);
        const isStreetNumber = c.name === "street_number";
        const hasCustomParseStreetNumber = !accentUtils.isEmpty(addressNumbers.streetNumber);

        const component = getAddressComponent(place, c.name);
        var value = getComponentValue(component, c);

        if (accentUtils.isEmpty(value)) {
            if (isUnit && hasCustomParseUnit) {
                return c.format.format(addressNumbers.unitNumber);
            }

            if (isStreetNumber && hasCustomParseStreetNumber) {
                return c.format.format(addressNumbers.streetNumber);
            }

            return null;
        }

        return c.format.format(value);
    }).where(v => !accentUtils.isEmpty(v)).toArray().join("");

    return addressModel;
}