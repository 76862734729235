import React from 'react'
import { Table } from 'reactstrap';
import { queryNoTracking, updateAsFormData } from '../../services/DataService'
import { accentUtils, t, newGuid, showDialog } from '../../services/HelperService'
import { Helper } from '../../services/JobService'
import { AccentSpinner } from './../AccentSpinner'
import AccentIcons from '../../icons/Icons'
import { AccentPopup} from '../../dialogs/AccentPopup';
import { getContext, getContextData } from '../../services/EntityContextService';
import { EmailDlg, mapToBaseEmailViewModel } from '../../dialogs/EmailDlg';
import { isMobileOnly } from '../AccentDisplay';
import { SmsDlg } from '../../dialogs/SmsDlg';


const query_Contact_Info = queryNoTracking('Contact_Info');
const query_ContactSmsDetails = queryNoTracking('ContactSmsDetails');
const query_ContactEmailDetails = queryNoTracking('ContactEmailDetails');



function getDefaultContext(id) {

    var context = getContext();

    if (!accentUtils.isNull(context)) return context;

    return {
        id: id,
        type: "Contact"
    };
}


export async function showContactEmailDlg(id, getAIContext) {

    var context = getDefaultContext(id);

    const ctx = await getContextData(context);

    var JobID = ctx.JobID;
    var OpportunityID = ctx.OpportunityID;
    var ActivityID = ctx.ActivityID;
    var InvoiceID = ctx.InvoiceID;
    var StatementID = ctx.StatementID;
    var SupplyJobID = ctx.PurchaseOrderID;


    const info = await query_ContactEmailDetails.getFirstOrDefault({ contactId: id, jobId: (JobID ? JobID : 0), opportunityId: (OpportunityID ? OpportunityID : 0), activityId: (ActivityID ? ActivityID : 0), invoiceId: (InvoiceID ? InvoiceID : 0), supplyJobId: (SupplyJobID ? SupplyJobID : 0), statementId: (StatementID ? StatementID : 0) });

    const result = await showDialog(<EmailDlg
        title="application_strings.views.settings.leadsIntegration.emailDocumentation"
        templates={info.Templates}
        to={info.To}
        getAIContext={getAIContext}
        attachments={info.Attachments}
        onSend={m => {
            return new Promise(sp => {

                const model = mapToBaseEmailViewModel(
                    m,
                    id,
                    info.Data.User.FullName,
                    {
                        JobID: JobID ?? -99,
                        ActivityID: ActivityID ?? -99,
                        InvoiceID: InvoiceID ?? -99,
                        ContactID: id,
                        OpportunityID: OpportunityID ?? -99
                    });


                updateAsFormData('EmailContact', model).then(function (sr) {
                    Helper.processEmailSendResult(sp, sr);
                });

            });
        }}
    />);

    return result;

}

export async function showContactSMSDlg(id) {

    var context = getDefaultContext(id);


    const ctx = await getContextData(context);
    const info = await query_ContactSmsDetails.getFirstOrDefault({
        id: id,
        activity: !accentUtils.isEmpty(ctx.ActivityID),
        job: !accentUtils.isEmpty(ctx.JobID),
        opportunity: !accentUtils.isEmpty(ctx.OpportunityID),
        invoice: !accentUtils.isEmpty(ctx.InvoiceID),
        supply: !accentUtils.isEmpty(ctx.PurchaseOrderID),
        statement: !accentUtils.isEmpty(ctx.StatementID),
        webLead: !accentUtils.isEmpty(ctx.WebLeadID)
    });

    
    const result = await showDialog(<SmsDlg context={ctx} info={info} />);
    

    if (!result.canceled) {
        Helper.sendSms(
            result.to.join(","),
            result.Body,
            result.ContactID,
            result.JobID,
            result.OpportunityID,
            result.ActivityID,
            result.TemplateID,
            result.DeliveryReceipt,
            result.InvoiceID);
    }

    return result;


}

export class ContactInfoCtrl extends React.Component {

    constructor(props) {
        super(props);
        this.id = 'id_cic_' + newGuid();

        this.ctrl = React.createRef();


        this.state = {
            popoverOpen: false,
            data: null,
            sendingEmail: false,
            sendingSms: false
        };

        this.toggle = this.toggle.bind(this);
        this.toggleEmail = this.toggleEmail.bind(this);
        this.toggleSms = this.toggleSms.bind(this);
        this.close = this.close.bind(this);
        this.showDialog = this.showDialog.bind(this);
        this.onAddressClick = this.onAddressClick.bind(this);
        this.onSendEmail = this.onSendEmail.bind(this);
        this.onSendSms = this.onSendSms.bind(this);
        this.onSmsClose = this.onSmsClose.bind(this);
        this.onEmailClose = this.onEmailClose.bind(this);
        this.openEmailDialog = this.openEmailDialog.bind(this);
        this.openSmsDialog = this.openSmsDialog.bind(this);
        this.loadWithoutSelection = this.loadWithoutSelection.bind(this);
        
        
    }

    componentDidMount() {
        if (this.props.popoverOpen) {
            this.loadWithoutSelection();
        }
    }

    onSendEmail() {
        this.setState({
            popoverOpen: false,
            sendingEmail: true
        });
        
    }

    

    openEmailDialog() {
        var me = this;
        this.state.sendingEmail = false;

        showContactEmailDlg(me.props.id, me.props.getAIContext);
    
    }

    openSmsDialog() {
        var me = this;
        this.state.sendingSms = false;

        showContactSMSDlg(me.props.id).then(r => {
            me.onSmsClose();
        });

    }

    onSendSms() {
        this.setState({
            popoverOpen: false,
            sendingSms: true
        });
    }

    onSmsClose() {        
        this.setState({
            sendingSms: false
        });
    }

    onEmailClose() {
        this.setState({
            sendingEmail: false
            
        });
    }

    close(e) {

        if (e?.target?.id === this.id) return;

        this.setState({
            popoverOpen: false,
        });

    }

    loadWithoutSelection() {
        var me = this;
        if (this.state.data === null) {
            query_Contact_Info.getFirstOrDefaultWithCache({ id: this.props.id })
                .then(function (res) {
                me.setState({ data: res, popoverOpen:true });                
            });
        }
    }

    toggle(e) {

        e.stopPropagation();


        var me = this;


        if (me.props.onClicking) me.props.onClicking();

        

        var open = !this.state.popoverOpen;

        this.setState({
            popoverOpen: open,
        });
        if (open && this.state.data === null) {
            query_Contact_Info.getFirstOrDefaultWithCache({ id: this.props.id })
                .then(function (res) {
                    me.setState({ data: res });
                });
        }

    }

    toggleEmail() {

        var me = this;

        var open = !this.state.sendingEmail;

        this.setState({
            sendingEmail: open,
        });


    }

    toggleSms() {

        var me = this;

        var open = !this.state.sendingSms;

        this.setState({
            sendingSms: open,
        });


    }


    showDialog(data) {

        if (!accentUtils.isNull(data)) {

            this.id = data.elementID;

            this.setState({
                data: data.healthCheck,
                popoverOpen: true
            });
        }
    }

    onAddressClick() {

        var a = this.state.data.Address;
        var lines = accentUtils.isNull(a) ? "" : a.AddressLines + ", " + a.City + ", " + a.Postcode + ", " + a.State + ", " + a.Country;

        window.open(`https://maps.google.com/maps/search/?api=1&query=${lines}`, "_blank");


    }


    render() {
        var me = this;
        var content = null;

        if (accentUtils.isNull(this.state.data)) {
            content = <AccentSpinner />;
        } else {
            if (this.state.sendingEmail) {
                this.openEmailDialog();
            } else if (this.state.sendingSms) {
                this.openSmsDialog();
            }

            const iconStyle = {
                width: "40px",
                marginTop: "-10px"
            };

            var maxEmailWidth = isMobileOnly ? '100px' : '200px';
            var sendEmail = (this.state.data.Email && this.state.data.Email !== '') ? (<td><AccentIcons.Email style={iconStyle} onClick={this.onSendEmail} /></td>) : (<td></td>);
            var sendSms = (this.state.data.Mobile && this.state.data.Mobile !== '') ? (<td><AccentIcons.SMS style={iconStyle} onClick={this.onSendSms} /></td>) : (<td></td>);

            content = (<Table>
                <tbody>
                    <tr>
                        <td><label>{t("application_strings.views.contactInfo.name")}</label></td>
                        <td>{this.state.data.FullName}</td><td></td>
                    </tr>
                    <tr>
                        <td><label>{t("application_strings.views.contactInfo.jobCategory")}</label></td>
                        <td>{this.state.data.JobCategory}</td><td></td>
                    </tr>
                    <tr>
                        <td><label>{t("application_strings.views.contactInfo.jobTitle")}</label></td>
                        <td>{this.state.data.JobTitle}</td><td></td>
                    </tr>
                    <tr>
                        <td><label>{t("application_strings.views.contactInfo.email")}</label></td>
                        <td><div style={{ display: 'block', overflow: 'hidden', width: maxEmailWidth, textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{this.state.data.Email}</div></td>                            
                        {sendEmail}
                    </tr>
                    <tr>
                        <td><label>{t("application_strings.views.contactInfo.mobile")}</label></td>
                        <td><a href={`tel:${this.state.data.Mobile}`}>{this.state.data.Mobile}</a></td>
                        {sendSms}
                    </tr>
                    <tr>
                        <td><label>{t("application_strings.views.contactInfo.workPhone")}</label></td>
                        <td><a href={`tel:${this.state.data.WorkPhone}`}>{this.state.data.WorkPhone}</a></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><label>{t("application_strings.views.contactInfo.homePhone")}</label></td>
                        <td><a href={`tel:${this.state.data.HomePhone}`}>{this.state.data.HomePhone}</a></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><label>{t("application_strings.views.contactInfo.address")}</label></td>
                        <td><div className="acc-link" onClick={this.onAddressClick}>{this.state.data.Address.Formatted}</div></td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>);

        }



        const clickElement = <AccentIcons.Info ref={this.ctrl} id={this.id} onClick={ this.toggle } top={this.props.isLarge ? 0 : !accentUtils.isNull(this.props.offset) ? this.props.offset: 7 } />;


        return <div className={`contactInfo ${this.props.isLarge ? "contactInfo-large" : ""}`} style={this.props.style}>
            {clickElement}
            <AccentPopup className="contactInfoPop" popupAlign={this.props.popupAlign} anchorAlign={this.props.anchorAlign} show={this.state.popoverOpen}
                onAutoClose={ this.close}
                anchor={this.ctrl}
                headerContent={<div className="d-flex">
                    <div className="me-auto">{t("application_strings.views.contactInfo.title")}</div>
                    <div><AccentIcons.Close className="acc-btn" onClick={this.close} /></div>
                </div>}
            >               
                {content}               
            </AccentPopup>
        </div>;
    }
}
