import * as React from "react";
import { Row, Col} from "reactstrap";
import { AccentCheckUnbound } from "../controls/unbound/AccentCheckUnbound";
import { showWait, t } from "../services/HelperService";
import { Helper } from "../services/JobService";
import { AccentWizard, AccentWizardPage } from "./RTLWizard";
import { getAccess } from "../services/UserService";


export default class RePriceJobWizard extends React.Component {

    constructor(props) {
        super(props);

        var me = this;
        this.job = this.props.pageprops.job;

        this.clearDiscounts = false;

        this.wizardModel = {
            viewModel: {
                entity: {
                    setProperty: function (name, value) {
                        this[name] = value;
                    }
                }
            }
        };

        this.allowReCalculateTaxes = getAccess().featureFlags().FeatureMultiTaxes();

        this.onClearDiscounts = this.onClearDiscounts.bind(this);
        this.onReCalcTaxes = this.onReCalcTaxes.bind(this);
        this.onFinish = this.onFinish.bind(this);

    }

    onClearDiscounts(e) {
        this.clearDiscounts = e.checked;
    }

    onReCalcTaxes(e) {
        this.reCalcTaxes = e.checked;
    }


    onFinish(e) {

        const done = showWait();

        return new Promise(p => {

            Helper.priceJob({
                ID: this.job.ID,
                ClearDiscounts: this.clearDiscounts,
                RecalculateTaxes: this.reCalcTaxes,
                JobStage: this.job.Stage
            }).then(r => {
                done().then(p);
            });

        });

        
    }

    render() {

        var nextTitle = undefined;

        if (this.props.pageIndex == 0) {
            nextTitle = "application_strings.wizards.rePriceJob.priceNow"
        }

        return (
            <AccentWizard wizardProps={this.props}>
                <AccentWizardPage title="application_strings.wizards.rePriceJob.page01Title" nextTitle={nextTitle}>
                    <React.Fragment>
                        <div className="alert alert-info" role="alert">{t("application_strings.wizards.rePriceJob.page01Message")}</div>
                        <Row>
                            <Col md="12">
                                <AccentCheckUnbound tagName="clearDiscounts" defaultValue={false} onChange={this.onClearDiscounts} label="application_strings.wizards.rePriceJob.clearDiscounts" />
                            </Col>
                        </Row>
                        {this.allowReCalculateTaxes && <Row>
                            <Col md="12">
                                <AccentCheckUnbound tagName="reCalcTaxes" defaultValue={false} onChange={this.onReCalcTaxes} label="application_strings.wizards.rePriceJob.reCalcTaxes" />
                            </Col>
                        </Row>}
                    </React.Fragment>
                </AccentWizardPage>
            </AccentWizard>
        );
    }

}
