import * as React from 'react';
import * as KendoLayout from '@progress/kendo-react-layout'
import { AccentButton } from '../controls/AccentButton';
import { accentUtils, from, showOK, showWait, t } from '../services/HelperService';
import { AccentDialog } from './AccentDialog';
import { AccentTokenCtrl } from '../controls/AccentTokenCtrl';
import { AccentUploadButton } from '../controls/AccentUpload';
import { AccentComboUnbound } from '../controls/unbound/AccentComboUnbound';
import { AccentTextUnbound } from '../controls/unbound/AccentTextUnBound';
import { AccentHtmlUnbound } from '../controls/unbound/AccentHtmlUnbound';
import { isMobileOnly, usePosition } from '../controls/AccentDisplay';
import { RTLDocumentGroupPreview } from '../controls/documents/DocumentPreview';
import { queryNoTracking } from '../services/DataService';
import { Settings } from '../services/AppSettingsService';
import { useValidation } from '../controls/bound/AccentFormCtrl';
import { AccentCheckUnbound } from '../controls/unbound/AccentCheckUnbound';
import { getUser } from '../services/UserService';
import { getContext, getContextData } from '../services/EntityContextService';


const query_GenerateEmailTemplateContent = queryNoTracking("GenerateEmailTemplateContent", undefined, true);


const elementIDs = {
    body: "bodyCtrl",
    preview: "prevDocCtrl"
};


export function mapToBaseEmailViewModel(model, id, toDisplayName, other, internalDocID) {

    return {
        ID: id,
        ToDisplayName: toDisplayName,
        To: JSON.stringify(model.to),
        Subject: model.subject,
        Body: model.body,
        CopyMe: model.copyMe,
        DocumentParams: JSON.stringify(from(model.attachments).where(a => a.ID === internalDocID).select(a => a.Params).firstOrDefault()),
        InternalAttachments: JSON.stringify(from(model.attachments).where(a => a.ID !== internalDocID).select(a => a.ID).toArray()),
        ExternalAttachment: model.externalAttachments,
        ...other
    };

}


let sendInprogress = false;

export const EmailDlg = React.memo(props => {

    const uploadCtrl = React.useRef();

    const [previewShown, setPreviewShown] = React.useState(false);
    const [refreshKey, setRefreshKey] = React.useState(1);
    const [selectedTab, setSelectedTab] = React.useState(1);
    const [to, setTo] = React.useState(props.to ?? []);
    const [toData, setToData] = React.useState(props.to ?? []);
    const [templateID, setTemplateID] = React.useState(from(props.templates ?? []).firstOrDefault(t => t.ID === props.defaultTemplateID));
    const [attachments, setAttachments] = React.useState(props.attachments ?? []);
    const [subject, setSubject] = React.useState(null);
    const [body, setBody] = React.useState(null);
    const [copyMe, setCopyMe] = React.useState(false);
    const [documentsReady, setDocumentsReady] = React.useState((props.attachments?.length ?? 0) === 0);


    const user = getUser();

    const pos = usePosition(selectedTab === 1 ? elementIDs.body : elementIDs.preview);

    const validation = useValidation(".emailDlg");

    const onToChanged = React.useCallback(e => {

        const newToItems = [];
        const newToData = [...toData];

        e.value.forEach(function (t) {

            var orgItem = from(toData ?? []).firstOrDefault(i => t.value === i.Email);

            if (accentUtils.isNull(orgItem)) {
                orgItem = { Name: t.text, Email: t.text };
                newToData.push(orgItem);
                setRefreshKey(k => k + 1);
            }

            newToItems.push(orgItem);

        });

        setTo(newToItems);
        setToData(newToData);



    }, [props, setTo, toData, setToData, setRefreshKey]);

    const onAttachmentsChanged = React.useCallback(e => {

        setAttachments(attachments => attachments.filter(a => from(e).any(x => x.value === a.ID)));
 
    }, [setAttachments]);


    const displayTemplate = React.useCallback(async templateID => {

        const done = showWait();

        setTemplateID(templateID);


        if (accentUtils.isNull(templateID)) {
            await done();
            return;
        }

        const context = await getContextData(getContext());

        const args = {
            UseBody: true,
            TemplateID: templateID,
            ActivityID: context.ActivityID,
            ContactID: context.ContactID,
            JobID: context.JobID,
            OpportunityID: context.OpportunityID,
            StatementID: context.CompanyID,
            SupplyJobID: context.PurchaseOrderID,
            InvoiceID: context.InvoiceID,
            WebLeadID: context.WebLeadID
        };

        const data = await query_GenerateEmailTemplateContent.getFirstOrDefaultWithCache(args)

        setSubject(data.Subject);
        setBody(data.Body);
        setRefreshKey(k => k + 1);


        done();

    }, [setRefreshKey, setSubject, setBody, setTemplateID]);


    const sendEmail = React.useCallback(async e => {

        const files = await uploadCtrl.current.getFilesForUpload();

        const result = {
            to: to,
            subject: subject,
            body: body,
            attachments: [...attachments],
            externalAttachments: files.files,
            copyMe: copyMe
        }

        sendInprogress = true;

        props.onSend(result).then(sendResult => {

            

            if (sendResult.retry) {
                sendEmail(e);
            } else if (!accentUtils.isNull(sendResult.error)) {
                showOK(sendResult.error.title, sendResult.error.msg, ["application_strings.application.buttons.ok"]).then(() => {
                    e.promise();
                    sendInprogress = false;
                });
            } else {

                showOK("application_strings.views.sendEmailDialog.titleResult", "application_strings.views.sendEmailDialog.sendEmailSuccess", ["application_strings.application.buttons.ok"], false, null, true, 1024).then(() => {

                    sendInprogress = false;
                    props.onClose();
                    e.promise();
                    
                });                                
            }            
        });        
    }, [props, subject, body, attachments, copyMe, to, sendInprogress]);

    const onTemplateChanged = async e => {

        const newTemplateID = e;

        await displayTemplate(newTemplateID);

        if (!accentUtils.isEmpty(props.emailTemplateAppSettingsField)) {
            Settings.SetAppSettings({ [props.emailTemplateAppSettingsField]: newTemplateID });
        }


    };


    const getAIContext = React.useCallback(async e => {

        if (props.getAIContext) {
            return await props.getAIContext({
                subject: subject,
                body: body
            });
        }

    }, [props, subject, body]);

    const handleSelect = React.useCallback(e => {
        setSelectedTab(e.selected);

        if (e.selected === 2) {
            setPreviewShown(true);
        }

    }, [setSelectedTab, setPreviewShown]);

    const showPreview = e => {
        handleSelect({ selected: 2 });

        e.promise();

    };


    const onDocumentsReady = React.useCallback(e => {

        setDocumentsReady(true);

    }, [documentsReady, setDocumentsReady]);


    React.useEffect(() => {

        if (accentUtils.isEmpty(templateID)) {
            if (!accentUtils.isEmpty(props.emailTemplateAppSettingsField)) {
                const defaultTemplateID = Settings.GetAppSettings()[props.emailTemplateAppSettingsField];

                if (!accentUtils.isEmpty(defaultTemplateID)) {
                    onTemplateChanged(defaultTemplateID)
                }

            }
        }


    },[]);


    const toItems = to.map(function (r) {

        return {
            text: r.Name + ' ' + '(' + (accentUtils.isEmpty(r.Email?.trim()) ? t("application_strings.application.general.emailMissing") : r.Email) + ')',
            value: r.Email,
            error: accentUtils.isEmpty(r.Email?.trim()) ? true : false
        };
    });

    const toDataItems = toData.map(function (r) {
        return {
            text: r.Name + ' ' + '(' + (accentUtils.isEmpty(r.Email?.trim()) ? t("application_strings.application.general.emailMissing") : r.Email) + ')',
            value: r.Email,
            error: accentUtils.isEmpty(r.Email?.trim()) ? true : false
        };
    });


    var attchedItems = attachments.map(function (a) {
        return {
            text: a.Name,
            value: a.ID,
            readOnly: a.ReadOnly
        };
    });

    const hasAttachments = attachments.length > 0;
    const allowSend = validation.isValid && documentsReady && !sendInprogress;


    const height = pos.toScreenHeight - (selectedTab ===1 ? 90 : 20);


    const btn = previewShown || !hasAttachments
        ? <AccentButton key="1" disabled={!allowSend} tagName="sendEmail" onClick={sendEmail}>{t("application_strings.application.buttons.send")}</AccentButton>
        : <AccentButton key="1"  tagName="showPreview" onClick={showPreview}>{t("application_strings.application.buttons.preview")}</AccentButton>

    return <AccentDialog
        fullscreen
        onClose={ props.onClose}
        actionsContent={[btn]}
    >

        <KendoLayout.TabStrip className="emailDlg" selected={isMobileOnly ? selectedTab - 1 : selectedTab} onSelect={handleSelect} keepTabsMounted>
            {!isMobileOnly && <KendoLayout.TabStripTab title={t(props.title)} disabled></KendoLayout.TabStripTab>}

            <KendoLayout.TabStripTab title={t(isMobileOnly ? "application_strings.wizards.sendEmail.email.titleMobile" : "application_strings.wizards.sendEmail.email.title")}>
                <AccentTokenCtrl key={`e_${refreshKey}`} required value={toItems} data={toDataItems} onChange={onToChanged} label="application_strings.application.general.emailTo" />
                <AccentUploadButton ref={uploadCtrl} multiple={true} onChange={onAttachmentsChanged} defaultAttachments={attchedItems} label="application_strings.application.general.emailAttachments" />
                {(props.templates?.length ?? 0) > 0 && <AccentComboUnbound key={`t_${refreshKey}`} displayField="Description" valueField="ID" defaultValue={templateID} onChange={onTemplateChanged} data={props.templates} label="application_strings.application.general.emailTemplate" />}
                <AccentTextUnbound key={`s_${refreshKey}`} required defaultValue={subject} label="application_strings.application.general.emailSubject" onChange={setSubject} maxLength={ 250 } />
                <div id={elementIDs.body} >
                    <AccentHtmlUnbound key={`b_${refreshKey}`} required defaultValue={body} height={height} onChange={setBody} minTools={isMobileOnly} showAI={props.getAIContext} getAIContext={getAIContext} />
                </div>              
                <AccentCheckUnbound label={t("application_strings.application.general.emailCopyMe", { email: user.Email })} onChange={e=> setCopyMe(e.checked) } />
            </KendoLayout.TabStripTab>

            {hasAttachments && <KendoLayout.TabStripTab title={t(isMobileOnly ? "application_strings.wizards.sendEmail.preview.titleMobile":  "application_strings.wizards.sendEmail.preview.title")}>
                <RTLDocumentGroupPreview elementID={elementIDs.preview} documents={attachments} maxHeight={height} onReady={ onDocumentsReady } />
            </KendoLayout.TabStripTab>}
        </KendoLayout.TabStrip>

    </AccentDialog>;

});

