import * as React from 'react';
import { Row, Col, Input } from 'reactstrap';
import { AccentSpinner } from '../controls/AccentSpinner';
import { FilterButtons } from '../controls/FilterButtons';
import { JobLineItemRender } from '../controls/jobs/JobLineItemRender';
import { AccentCheckUnbound } from '../controls/unbound/AccentCheckUnbound';
import { AccentComboUnbound } from '../controls/unbound/AccentComboUnbound';
import { queryNoTracking } from '../services/DataService';
import { accentUtils, def, from, t } from '../services/HelperService';
import { Helper, jobLineTypes } from '../services/JobService';
import { AccentWizard, AccentWizardPage } from './RTLWizard';
import { ProductCombo } from '../controls/products/ProductCombo';
import { getProduct } from '../services/ProductService';


const query_Job_ParentJob_JobLineSelectList = queryNoTracking("Job_ParentJob_JobLineSelectList");

export class NewServiceLineWizard extends React.Component {

    constructor(props) {
        super(props);

        this.line = props.pageprops.line;
        this.defaultType = def(this.line.LineType, jobLineTypes.Alteration);
        this.hideService = props.pageprops.hideService??false;

        this.productRefreshKey = 1;

        this.state = {
            isLoading: true,
            selectedLineType: this.defaultType == jobLineTypes.Alteration ? 1 : (this.defaultType == jobLineTypes.Remake) ? 2 : 3,
            lineLinked: false,
            selectedLine: null,
            selectedProductID: null,
            selectedProductVersionID: null,
            lineData: []
        };


        this.componentDidMount = this.componentDidMount.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onLineTypeChanged = this.onLineTypeChanged.bind(this);
        this.onLinkChanged = this.onLinkChanged.bind(this);
        this.onSelectedLineChanged = this.onSelectedLineChanged.bind(this);
        this.onProductChanged = this.onProductChanged.bind(this);

    }

    componentDidMount() {

        query_Job_ParentJob_JobLineSelectList.getAll({ jobID: this.line.JobID }).then(r => {
            this.setState({ lineData: r, isLoading: false, lineLinked : r.length > 0});
        });


    }

    onProductChanged(e) {
        this.setState({selectedProductID : e?.ID});
    }

    async onFinish() {

        var me = this;

        const sourceLine = me.state.lineLinked && !accentUtils.isNull(me.state.selectedLine) ? me.state.selectedLine.Line : null;
        const originalLineID = accentUtils.isNull(sourceLine) ? null : sourceLine.ID;
        const selectedProductID = accentUtils.isNull(sourceLine) ? me.state.selectedProductID : null;


        let res = null;

        if (me.state.selectedLineType == "1") {
            res = await Helper.copyJobLineAsAlter(sourceLine, me.line, selectedProductID);
        } else if (me.state.selectedLineType == "2") {
            res = await Helper.copyJobLineAsRemake(sourceLine, me.line, selectedProductID);
        } else {
            res = await Helper.copyJobLineAsService(sourceLine, me.line, selectedProductID);
        }

        var product = getProduct(res.ProductID);

        res.ProductVersionID = product?.VersionID;

        return { newLine: res, originalLineID: originalLineID };

    }

    onLineTypeChanged(e) {

        let prod = this.state.selectedProductID;

        if (prod) {

            var product = getProduct(prod);

            if (e.value != "3" && product?.IsMultiConfig) {
                prod = null;
                this.productRefreshKey++;
            }

        }

        this.setState({ selectedLineType: e.value, selectedProductID : prod});
    }

    onLinkChanged(e) {
        this.setState({ lineLinked: e.checked });
    }

    onSelectedLineChanged(e) {
        const selectedLine = from(this.state.lineData).firstOrDefault(l => l.Line.ID == e);

        this.setState({ selectedLine: selectedLine });

    }

    render() {


        const isLinkable = this.state.lineData.length > 0 && this.state.selectedLineType != "3";

        const showProductSelector = this.state.selectedLineType == "3" || (isLinkable && !this.state.lineLinked);

        const allowNext = !isLinkable || !this.state.lineLinked || !accentUtils.isNull(this.state.selectedLine);

        const typeOptions = [
            { ID: 1, Text: t("application_strings.wizards.newServiceLine.page01Alter"), tagName: 'v1' },
            { ID: 2, Text: t("application_strings.wizards.newServiceLine.page01Remake"), tagName: 'v2' }            
        ];

        if (!this.hideService) {
            typeOptions.push({ ID: 3, Text: t("application_strings.wizards.newServiceLine.page01Service"), tagName: 'v3' });
        }


        return (
            <AccentWizard wizardProps={this.props} allowNext={allowNext}>
                <AccentWizardPage title={"application_strings.wizards.newServiceLine.page01Title"} >
                    {this.state.isLoading ? <AccentSpinner /> :
                        <React.Fragment>
                            <div className="alert alert-info" role="alert">{t("application_strings.wizards.newServiceLine.page01Message")}</div>
                            <Row>
                                <Col md={12}>
                                    <FilterButtons defaultValue={this.state.selectedLineType} options={typeOptions} onChange={this.onLineTypeChanged} tagName="serviceType" />
                                </Col>
                            </Row>
                            <hr />
                            {isLinkable && <Row>
                                <Col md={12}>
                                    <AccentCheckUnbound tagName="linkChk" defaultValue={this.state.lineLinked} onChange={this.onLinkChanged} label="application_strings.wizards.newServiceLine.page01LinkExisting" />
                                </Col>
                            </Row>}
                            {isLinkable && this.state.lineLinked && <Row>
                                <Col md={12}>
                                    <AccentComboUnbound
                                        displayField="JobLineNo"
                                        valueField="ID"
                                        required
                                        itemRender={JobLineItemRender}
                                        onChange={this.onSelectedLineChanged}
                                        label="application_strings.wizards.newServiceLine.selectLine"
                                        data={this.state.lineData.filter(l => ((this.state.selectedLineType === 1 && Helper.jobLineAllowCreateAlter(l.Line)) || (this.state.selectedLineType === 2 && Helper.jobLineAllowCreateRemake(l.Line)))  && (accentUtils.isNull(l.ServiceLine) || !l.ServiceLine.IsOpen)).map(l => l.Line)} />
                                </Col>
                            </Row>}
                            {isLinkable && this.state.lineLinked && !accentUtils.isNull(this.state.selectedLine) && !accentUtils.isNull(this.state.selectedLine.ServiceLine) && this.state.selectedLine.ServiceLine.HasService && <Row>
                                <Col md={12}>
                                    <div className="alert alert-warning" role="alert">{t("application_strings.wizards.newServiceLine.page01ExistingService", this.state.selectedLine.ServiceLine)}</div>
                                </Col>
                            </Row>}

                            {showProductSelector && <Row>
                                <Col md={12}>
                                    <ProductCombo
                                        key={ this.productRefreshKey }
                                        label="application_strings.application.dialogs.productSelectionTitle"
                                        required
                                        defaultProductID={this.state.selectedProductID}
                                        filter_showAllMemberProducts={this.state.selectedLineType != "3"}
                                        filter_showMultiProducts={this.state.selectedLineType == "3"}
                                        onChange={this.onProductChanged}
                                    />
                                </Col>
                            </Row>}


                        </React.Fragment>
                    }
                </AccentWizardPage>
            </AccentWizard>
        );
    }

}
