import React from 'react';
import { Helper } from '../services/JobService';

const AICreditDisplay = ({ refreshCount}) => {
    // Sample data - in real usage this would come from props
    const [totalCredits, setTotalCredits] = React.useState(0);
    const [usedCredits, setUsedCredits] = React.useState(0);

    const remainingCredits = totalCredits - usedCredits;
    const percentageUsed = (usedCredits / totalCredits) * 100;

    const styles = {
        container: {
            width: '300px',
            padding: '20px',
            borderRadius: '8px',
            backgroundColor: '#ffffff',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            fontFamily: 'system-ui, -apple-system, sans-serif',            
        },
        header: {
            margin: '0 0 20px 0',
            fontSize: '18px',
            fontWeight: '600',
            color: '#333',
        },
        progressContainer: {
            height: '8px',
            backgroundColor: '#e0e0e0',
            borderRadius: '4px',
            overflow: 'hidden',
            margin: '10px 0',
        },
        progressBar: {
            height: '100%',
            width: `${percentageUsed}%`,
            backgroundColor: percentageUsed > 90 ? '#ef4444' : '#3b82f6',
            transition: 'width 0.3s ease',
        },
        statsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
        },
        stat: {
            textAlign: 'center',
        },
        label: {
            fontSize: '12px',
            color: '#666',
            marginBottom: '4px',
        },
        value: {
            fontSize: '16px',
            fontWeight: '600',
            color: '#333',
        }
    };


    React.useEffect(() => {

        Helper.getAIQueryRemainingCredits().then(res => {

            setTotalCredits(res.Limit);
            setUsedCredits(res.Credits);

        });

    }, [refreshCount]);

    return (
        <div style={styles.container}>
            <h2 style={styles.header}>AI Credit Status</h2>
            <div style={styles.progressContainer}>
                <div style={styles.progressBar}></div>
            </div>
            <div style={styles.statsContainer}>
                <div style={styles.stat}>
                    <div style={styles.label}>Remaining</div>
                    <div style={styles.value}>{remainingCredits}</div>
                </div>
                <div style={styles.stat}>
                    <div style={styles.label}>Used</div>
                    <div style={styles.value}>{usedCredits}</div>
                </div>
                <div style={styles.stat}>
                    <div style={styles.label}>Total Limit</div>
                    <div style={styles.value}>{totalCredits}</div>
                </div>
            </div>
        </div>
    );
};

export default AICreditDisplay;