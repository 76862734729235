import React from 'react'
import { Row, Col, InputGroup, InputGroupAddon, Button } from 'reactstrap'

import { accentUtils, t, newGuid, showOK, from, formHelper, showDialog } from '../../services/HelperService'
import { loadEntityNavigationPropertyValue, attachEntity, addEntity, setEntityMemberValue } from '../../services/DataService'

import AccentIcons from '../../icons/Icons'
import { AccentFormCtrl, AccentFormCtrlNoViewState } from './AccentFormCtrl'
import { AccentLocationServiceMsg } from '../AccentMap'
import { AccentAddressLocator } from '../AccentAddressLocator'
import { AccentSpinner } from '../AccentSpinner'
import { google } from '../../services/registerServices'
import { useViewState } from '../../app-framework/ViewState'
import { getAddressFromPlace, getRequiredAddressFields } from '../../services/GlobalizationService'



class AccentAddressError extends React.Component {

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {

        console.log(error);
        console.log(errorInfo);

    }

    constructor(props) {
        super(props);

        this.state = { hasError: false };

    }


    render() {

        if (this.state.hasError) return <p>Error loading address. Please refresh to try again.</p>;


        return <AccentAddressCtrl ref={this.props.innerRef} {...this.props} />;

    }


}

export const AccentAddress = React.forwardRef((props, ref) => {

    const info = useViewState();

    return <AccentAddressError innerRef={ref} {...props} updateViewState={ info.update } />;

});

export const AccentAddressNoViewState = React.forwardRef((props, ref) => {



    return <AccentAddressError innerRef={ref} {...props} noViewState={true } />;

});


class AccentAddressCtrl extends React.Component {


 
    constructor(props) {
        super(props);



        this.id = 'a' + newGuid();

        this.ctrl = React.createRef();
        this.autoComp = React.createRef();

        this.addressLineRefreshKey = 1;

        this.loading = false;

        this.readOnly = false;
        this.required = true;

        if (!accentUtils.isNull(this.props.readOnly)) {
            this.readOnly = this.props.readOnly;
        }

        if (!accentUtils.isNull(this.props.required)) {
            this.required = this.props.required;
        }

        this.geolocationComplete = false;

        this.state = {
            hasError : false,
            showLocationOffMsg: true,
            placeSearch: null,
            autocomplete: null,
        };

        this.onMapsClick = this.onMapsClick.bind(this);
        this.getAddressFieldPath = this.getAddressFieldPath.bind(this);
        this.getAddress = this.getAddress.bind(this);
        this.getAddressGeo = this.getAddressGeo.bind(this);
        this.onShowLocation = this.onShowLocation.bind(this);
        this.tryFindAddressGeo = this.tryFindAddressGeo.bind(this);
        this.onAddressLocated = this.onAddressLocated.bind(this);
        this.onAddressFieldChanged = this.onAddressFieldChanged.bind(this);
        this.isEntityLoaded = this.isEntityLoaded.bind(this);
        this.loadAddress = this.loadAddress.bind(this);
        this.copyAddressFrom = this.copyAddressFrom.bind(this);
        this.copyAddressTo = this.copyAddressTo.bind(this);
        this.registerLookup = this.registerLookup.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.refresh = this.refresh.bind(this);
        this.geolocate = this.geolocate.bind(this);
        this.fillInAddress = this.fillInAddress.bind(this);
        this.onGeolocateError = this.onGeolocateError.bind(this);
        this.render = this.render.bind(this);
        this.notifyDirty = this.notifyDirty.bind(this);
        
        
    }


    notifyDirty() {
        if (this.props.updateViewState) {
            this.props.updateViewState({ isDirty: true });
        }
    }

    getAddress() {
        if (accentUtils.isNull(this.props.addressMember)) {
            return this.props.entity;
        } else {
            return this.props.entity[this.props.addressMember]
        }
    }

    onMapsClick() {

        const a = this.getAddress();

        var lines = accentUtils.isNull(a) ? "" :  a.AddressLines + ", " + a.City + ", " + a.Postcode + ", " + a.State + ", " + a.Country;

        window.open(`https://maps.google.com/maps/search/?api=1&query=${lines}`, "_blank");
        
    }

    onAddressLocated(pos, forceUpdate) {

        if (!accentUtils.isNull(pos)) {

            var addr = this.getAddress();
            if (!accentUtils.isNull(addr)) {

                if (pos.changed || forceUpdate) {

                    addr.PosLat = pos.lat;
                    addr.PosLong = pos.lng;

                    if (!accentUtils.isNull(this.props.onChange))
                        this.props.onChange();


                    this.notifyDirty();

                    this.forceUpdate();

                }
            }

        }

    }
 

    isEntityLoaded(){
        return !accentUtils.isNull(this.getAddress());  
    }
   
    loadAddress(){
      
       var me = this;
        if (!me.isEntityLoaded()){

            if (!me.loading) {
                me.loading = true;
                loadEntityNavigationPropertyValue(this.props.entity, this.props.addressMember).then(async function (addr) {

                    if (accentUtils.isNull(addr)) {

                        const newAddress = await addEntity("Address", {});

                        setEntityMemberValue(me.props.entity, me.props.addressMember, newAddress);


                        me.notifyDirty();
                    }

                    me.loading = false;
                    me.refresh();
                });
            }
        }  
    }

    copyAddressTo(address) {

        var existingAddress = this.getAddress();

        if (!accentUtils.isNull(existingAddress) && !accentUtils.isNull(address)) {
            address.AddressLines = existingAddress.AddressLines;
            address.City = existingAddress.City;
            address.State = existingAddress.State;
            address.Postcode = existingAddress.Postcode;
            address.Country = existingAddress.Country;
            address.PosLong = existingAddress.PosLong;
            address.PosLat = existingAddress.PosLat;

        }

    }

    copyAddressFrom(address){
        
        var existingAddress = this.getAddress();
        
        if (!accentUtils.isNull(existingAddress)){
            existingAddress.AddressLines = address.AddressLines;
            existingAddress.City = address.City;
            existingAddress.State = address.State;
            existingAddress.Postcode = address.Postcode;
            existingAddress.Country = address.Country;
            existingAddress.PosLong = address.PosLong;
            existingAddress.PosLat = address.PosLat;
            
            
            if (!accentUtils.isNull(this.props.onChange))
                this.props.onChange();

            
            this.addressLineRefreshKey++;

            this.refresh();
        }
        
    }
    registerLookup(){
        if (accentUtils.isNull(this.state.autocomplete) && !accentUtils.isNull(this.autoComp.current)){

            var element = this.autoComp.current;

            var autocomplete = new google.maps.places.Autocomplete(
                element,
                { types: ['address'] });

            // When the user selects an address from the dropdown, populate the address
            // fields in the form.
            var listener = autocomplete.addListener('place_changed', this.fillInAddress);
            this.setState({autocomplete : autocomplete, autocompleteListener: listener});
        }

    }

    componentDidUpdate(){
        this.loadAddress();
        
        this.registerLookup();
    }

    componentDidMount() {
        this.loadAddress();        
        this.registerLookup();
    }

    componentWillUnmount() {
        if (google){
            google.maps.event.removeListener(this.state.autocompleteListener);
        }                       
    }

    refresh(){
        this.forceUpdate();
    }


    getAddressGeo() {

        var result = null;

        var addr = this.getAddress();
        if (!accentUtils.isNull(addr)) {
            if (!accentUtils.isNull(addr.PosLat) && !accentUtils.isNull(addr.PosLong)) {
                result = { lat: addr.PosLat, lng: addr.PosLong }
            }
        }


        return result;
    }

    onShowLocation() {

        var me = this;

        var currLoc = this.getAddressGeo();
        const hasPos = !accentUtils.isNull(currLoc);


        if (!accentUtils.isNull(currLoc)) {
            showDialog(<AccentAddressLocator location={currLoc} draggable={true} />).then(p => {

                if (!p.canceled) {
                    me.onAddressLocated(p, !hasPos); 
                }

            });
        } else {

            this.tryFindAddressGeo().then(function (res) {
                

                showDialog(<AccentAddressLocator location={res} draggable={true} />).then(p => {

                    if (!p.canceled) {
                        me.onAddressLocated(p, !hasPos);
                    }

                });

                
            });

        }
    }

    tryFindAddressGeo() {
        var addr = this.getAddress();
        return lookupGeocode(addr);
    }

    onGeolocateError(error) {

        var me = this;


        if (this.state.showLocationOffMsg && error.code === error.PERMISSION_DENIED) {
            showOK("application_strings.application.dialogs.locationServicesOffTitle", < AccentLocationServiceMsg />, ["application_strings.application.buttons.ok"], false, null, true).then(function (res) {
                me.setState({ showLocationOffMsg: false });
            });
        }
    }


    geolocate() {

        var me = this;

        if (!me.geolocationComplete && navigator.geolocation && !accentUtils.isNull(me.state.autocomplete)) {
            navigator.geolocation.getCurrentPosition(function(position) {
                var geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

                var circle = new google.maps.Circle({
                    center: geolocation,
                    radius: position.coords.accuracy
                });

                me.state.autocomplete.setBounds(circle.getBounds());
                me.geolocationComplete = true;
            }, me.onGeolocateError);
        }
    }


    fillInAddress() {
        var place = this.state.autocomplete.getPlace();
        const placeText = this.autoComp.current.value;


        var addressModel = getAddressFromPlace(place, placeText)

        this.copyAddressFrom(addressModel);

    }

    onAddressFieldChanged(e) {
        
        var prevIsNull = accentUtils.isNull(e.previous);
        var currIsNull = accentUtils.isNull(e.value);

        if (prevIsNull !== currIsNull || e.previous !== e.value) {

            var addr = this.getAddress();
            if (!accentUtils.isNull(addr)) {
                addr.PosLong = null;
                addr.PosLat = null;

                this.forceUpdate();
            }
        }


        if (this.props.onChange) {
            this.props.onChange(e);
        }

    }

    getAddressFieldPath(field) {

        if (accentUtils.isNull(this.props.addressMember)) {
            return field;
        } else {
            return this.props.addressMember + "." + field;
        }

    }

    render() {

        if (!this.isEntityLoaded()){
            return (<AccentSpinner />);
        }


        const readOnly = this.props.readOnly;

        var me = this;


        const addr = this.getAddress();

        var requireFields = getRequiredAddressFields(addr?.Country);

        const requireAddressLines = this.props.required && requireFields?.addressLines;
        const requireCity = this.props.required && requireFields?.city;
        const requireState = this.props.required && requireFields?.state;
        const requirePostcode = this.props.required && requireFields?.postcode;
        const requireCountry = this.props.required && requireFields?.country;

        var model = {

            lines: formHelper.getFieldCtrlModel(addr, "AddressLines", "textArea", requireAddressLines, { label: "", placeHolder: "application_strings.application.general.addressLinesPlaceHolder", readOnly: readOnly, onChange: this.onAddressFieldChanged }),
            city: formHelper.getFieldCtrlModel(addr, "City", "text", requireCity, { label: "application_strings.entities.address.City", placeHolder: "application_strings.application.general.addressCityPlaceHolder", readOnly: readOnly, onChange: this.onAddressFieldChanged }),
            state: formHelper.getFieldCtrlModel(addr, "State", "text", requireState, { label: "application_strings.entities.address.State", placeHolder: "application_strings.application.general.addressStatePlaceHolder", readOnly: readOnly, onChange: this.onAddressFieldChanged }),
            postcode: formHelper.getFieldCtrlModel(addr, "Postcode", "text", requirePostcode, { label: "application_strings.entities.address.Postcode", placeHolder: "application_strings.application.general.addressPostcodePlaceHolder", readOnly: readOnly, onChange: this.onAddressFieldChanged }),
            country: formHelper.getFieldCtrlModel(addr, "Country", "text", requireCountry, { label: "application_strings.entities.address.Country", placeHolder: "application_strings.application.general.addressCountryPlaceHolder", readOnly: readOnly, onChange: this.onAddressFieldChanged}),
        
        };

        model.lines.field.options.rows = 2;


       var lookup = null;


       if (google) {

           

           var hasPos = false;
           var currLoc = this.getAddressGeo();
           
           if (!accentUtils.isNull(currLoc)) {
                hasPos = true;                
           }

      


           lookup = (
               <React.Fragment>                    
                   <Row>
                       <Col md={12}>
                           <InputGroup className={`accentAddressLookup ${hasPos? 'no-geo':''}`}>
                               <input className="form-control" disabled={readOnly} ref={this.autoComp} placeholder={t("application_strings.application.general.searchForAddress")} onFocus={this.geolocate} type="text" data-tagname={`${this.props.tagNamePrefix}_${this.props.addressMember}_Search`} />
                               {!hasPos && <Button color={hasPos ? "success" : "danger"} disabled={readOnly} onClick={this.onShowLocation} >GEO</Button>}
                               <Button color="primary" onClick={this.onMapsClick}><AccentIcons.Map /></Button>
                           </InputGroup>
                        </Col>
                    </Row>                   
                </React.Fragment>
            );
        }



        const noViewState = this.props.noViewState;

        return (
            <div ref={this.ctrl}>
                <Row className="accentAddressCtrl">
                    <Col md={12}>
                        {lookup}
                        <Row>
                            <Col md={12}>
                                {!noViewState && <AccentFormCtrl key={`${this.addressLineRefreshKey}_${requireAddressLines}`} model={model.lines} dataTagName={`${this.props.tagNamePrefix}_${this.props.addressMember}_AddressLines`} />}
                                {noViewState && <AccentFormCtrlNoViewState key={`${this.addressLineRefreshKey}_${requireAddressLines}`} model={model.lines} dataTagName={`${this.props.tagNamePrefix}_${this.props.addressMember}_AddressLines`} />}
                            </Col>                           
                        </Row>
                        <Row>
                            <Col md={12}>
                                {!noViewState && <AccentFormCtrl key={`${this.addressLineRefreshKey}_${requireCity}`} model={model.city} dataTagName={`${this.props.tagNamePrefix}_${this.props.addressMember}_City`} />}
                                {noViewState && <AccentFormCtrlNoViewState key={`${this.addressLineRefreshKey}_${requireCity}`} model={model.city} dataTagName={`${this.props.tagNamePrefix}_${this.props.addressMember}_City`} />}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                {!noViewState && <AccentFormCtrl key={`${this.addressLineRefreshKey}_${requireState}`} model={model.state} dataTagName={`${this.props.tagNamePrefix}_${this.props.addressMember}_State`} />}
                                {noViewState && <AccentFormCtrlNoViewState key={`${this.addressLineRefreshKey}_${requireState}`} model={model.state} dataTagName={`${this.props.tagNamePrefix}_${this.props.addressMember}_State`} />}
                            </Col>
                            <Col md={6}>
                                {!noViewState && <AccentFormCtrl key={`${this.addressLineRefreshKey}_${requirePostcode}`} model={model.postcode} dataTagName={`${this.props.tagNamePrefix}_${this.props.addressMember}_Postcode`} />}
                                {noViewState && <AccentFormCtrlNoViewState key={`${this.addressLineRefreshKey}_${requirePostcode}`} model={model.postcode} dataTagName={`${this.props.tagNamePrefix}_${this.props.addressMember}_Postcode`} />}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                {!noViewState && <AccentFormCtrl key={`${this.addressLineRefreshKey}_${requireCountry}`} model={model.country} dataTagName={`${this.props.tagNamePrefix}_${this.props.addressMember}_Country`} />}
                                {noViewState && <AccentFormCtrlNoViewState key={`${this.addressLineRefreshKey}_${requireCountry}`} model={model.country} dataTagName={`${this.props.tagNamePrefix}_${this.props.addressMember}_Country`} />}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}




function lookupGeocodeSearch(searchStr) {

    if (accentUtils.isEmpty(searchStr)) {
        return Promise.resolve(null);
    } else {


        return new Promise(function (p) {
            var service = new google.maps.places.AutocompleteService();
            service.getPlacePredictions({ input: searchStr }, function (predictions, status) {

                if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                    p({
                        isError: false,
                        isFound: false,
                    });
                    return;
                } else if (status !== google.maps.places.PlacesServiceStatus.OK) {
                    p({
                        isError: true,
                        isFound: false,
                    });
                    return;
                }

                var item = from(predictions).where(p => !accentUtils.isEmpty(p.place_id)).firstOrDefault();


                if (accentUtils.isNull(item)) {
                    p({
                        isError: false,
                        isFound: false,
                    });
                } else {
                    var ps = new google.maps.places.PlacesService(document.createElement('div'))
                    ps.getDetails({ fields: ["geometry.location"], placeId: item.place_id }, function (place, status) {
                        if (accentUtils.isNull(place)) {
                            p({
                                isError: false,
                                isFound: false,
                            });
                        } else {
                            p({
                                isError: false,
                                isFound: true,
                                lat: place.geometry.location.lat(),
                                lng: place.geometry.location.lng(),
                            });
                        }
                    });
                }


            });



        });
    }

}


function lookupGeocode(address) {

    var search = null;

    if (!accentUtils.isNull(address)) {
        search = "{0}, {1} {2}, {3}".format((accentUtils.isNull(address.AddressLines) ? '' : address.AddressLines).replace(/[,//]/g, '').replace(/[0-9]/g, ''), accentUtils.isNull(address.City) ? '' : address.City, accentUtils.isNull(address.State) ? '' : address.State, accentUtils.isNull(address.Country) ? '' : address.Country);

        if (search.replace(',', '').trim() === '') {
            search = null;
        }

    }

    if (accentUtils.isEmpty(search)) {
        return Promise.resolve(null);
    }

    return new Promise(function (p) {

        var words = search.split(' ');
        lookupGeocodeSearch(search).then(function (r) {
            if (r.isError) {
                p(null);
            } else if (!r.isFound) {
                words.shift(1);
                search = words.join(' ');

                lookupGeocodeSearch(search).then(function (r2) {
                    if (r2.isError) {
                        p(null);
                    } else if (!r2.isFound) {
                        p(null);
                    } else {
                        p(r2);
                    }
                });


            } else {
                p(r);
            }
        });
    });
}


