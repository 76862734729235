import * as React from 'react'
import { Button } from "@progress/kendo-react-buttons";
import { EditorUtils, Editor, EditorTools } from '@progress/kendo-react-editor';
import { Schema } from '@progress/kendo-editor-common';
import { accentUtils, showDialog } from '../../services/HelperService';
import { AIEmailEditDlg } from '../../dialogs/AIEmailEditDlg';
import { getAccess } from '../../services/UserService';

const {
    Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
    AlignLeft, AlignCenter, AlignRight, AlignJustify,
    Indent, Outdent, OrderedList, UnorderedList,
    Undo, Redo, FontSize, FontName, FormatBlock,
    Link, Unlink, InsertImage, ViewHtml,
    InsertTable,
    AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter,
    DeleteRow, DeleteColumn, DeleteTable,
    MergeCells, SplitCell
} = EditorTools;


const AIEdit = ({ view, readOnly, getContext, onChange }) => {



    
    const onEdit = React.useCallback(async () => {
        try {

            const context = await getContext();

            const selectResult = await showDialog(
                <AIEmailEditDlg context={context} />
            );

            if (selectResult.canceled) return;

            onChange({ body: selectResult.body });

            
        } catch (error) {
            console.error("Error inserting image:", error);
        }
    }, [onChange, getContext]);


    return (
        <Button
            onClick={onEdit}
            disabled={readOnly}
            onMouseDown={(e) => e.preventDefault()}
            onPointerDown={(e) => e.preventDefault()}
            title="AI Edit"
            icon="edit"
        />
    );
};


export const AccentHtmlUnbound = (props) => {

    const [refreshKey, setRefreshKey] = React.useState(1);

    const [value, setValue] = React.useState(EditorUtils.createDocument(new Schema({
        nodes: EditorUtils.nodes,
        marks: EditorUtils.marks
    }), props.defaultValue??"<p><p/>"));

    const onChange = event => {
        setValue(event.value);        

        if (props.onChange) {

            const val = accentUtils.isEmpty(event.value.textContent) ? null : event.html;

            props.onChange(val);
        }

    };

    const onAIChanged = e => {

        setValue(e.body);


        props.onChange(e.body);

        setRefreshKey(k => k + 1);
    }

    var tools = undefined;

    const allTools = props.allTools;
    const minTools = props.minTools;
    const height = (accentUtils.isNull(props.height)) ? 500 : props.height;


    if (allTools) {
        tools = [
            [Bold, Italic, Underline, Strikethrough],
            [Subscript, Superscript],
            [AlignLeft, AlignCenter, AlignRight, AlignJustify],
            [Indent, Outdent],
            [OrderedList, UnorderedList],
            FontSize, FontName, FormatBlock,
            [Undo, Redo],
            [Link, Unlink, InsertImage, ViewHtml],
            [InsertTable],
            [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
            [DeleteRow, DeleteColumn, DeleteTable],
            [MergeCells, SplitCell]
        ];
    } else {

        if (minTools) {
            tools =
                [
                    [Bold, Italic, Underline, Strikethrough],
                    [OrderedList, UnorderedList],
                    [Undo]
                ];

        } else {

            tools =
                [
                    [Bold, Italic, Underline, Strikethrough],
                    [Subscript, Superscript],
                    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                    [Indent, Outdent],
                    [OrderedList, UnorderedList],
                    FontSize, FontName, FormatBlock,
                    [Undo, Redo],
                    [Link, Unlink]
                ];

        }
    }

    if (props.showAI && props.getAIContext && getAccess().modules().RTL_AI()) {
        tools.push([p => <AIEdit {...p} getContext={props.getAIContext} onChange={onAIChanged} />]);
    }


    return <div className="accentHtml" data-tagname={props.dataTagName}>
        <Editor key={refreshKey} value={value} onChange={onChange} tools={tools} contentStyle={{ height: height }} />
    </div>;
};
