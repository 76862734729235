import * as React from 'react'
import { Alert, Table } from 'reactstrap'

import { accentUtils, t, from, format, def} from './../../services/HelperService'
import { AccentSpinner } from '../AccentSpinner'
import { AccentTooltip } from '../AccentTooltip'
import { ToolbarToggleButton } from '../FilterButtons'
import * as DataService from '../../services/DataService'
import * as ProductService from '../../services/ProductService'


const query_Job_PriceDetails = DataService.queryNoTracking("Job_PriceDetails");

var lineCostDiscountHack = {};

const JobPriceDetailsLineCtrl = props => {


    const padding = [];
    const level = accentUtils.isNull(props.level) ? 0 : props.level;


    const getPercentage = (n, d, sub1, emptyIfZero) => {
        if (accentUtils.isEmpty(d) || accentUtils.isEmpty(n)) {
            if (emptyIfZero) {
                return '';
            } else {
                return format.formatPercentage(0);
            }
        }

        const res = format.formatPercentage(accentUtils.roundNormal((n / d) - ((sub1) ? 1 : 0), 4));

        if ((res === "0%" || res === "-0%") && emptyIfZero) {
            return '';
        }

        console.log("getPercentage: ", res);
        return res;
    };

    const getMarkupPrecentage = (s, c) => {
        return getPercentage(s, c, true, false);
    };

    const getMarginPercentage = (s, c) => {
        return getPercentage(s - c, s, false, false);
    };

    const getMarkupAmount = (s, c) => {
        const r = s - c;

        return format.formatCurrency(r, false);

    };


    const discCost = props.cost + props.costAdhoc + props.discount;
    const disc = props.asPercentage ? getPercentage(-1.0 * props.discount, props.cost, false, true) : format.formatCurrency(props.discount * -1.0, true);
    const costBeforeAdhoc = props.cost + props.discount;


    const adhocCostDisc = props.asPercentage ? getPercentage(-1.0 * props.costAdhoc, costBeforeAdhoc, false, true) : format.formatCurrency(props.costAdhoc * -1.0, true);

    const salePriceBeforeAdhoc = props.retail + props.discountRetail;


    var salePrice = salePriceBeforeAdhoc + props.adhocDisc;

    if (Math.abs(accentUtils.round(salePrice, 2)) == 0) {
        salePrice = 0;
    }

    const custDisc = props.asPercentage ? getPercentage(-1.0 * props.discountRetail, props.retail, false, true) : format.formatCurrency(props.discountRetail * -1.0, true);

    const adhocDisc = props.asPercentage ? getPercentage(-1.0 * props.adhocDisc, salePriceBeforeAdhoc, false, true) : format.formatCurrency(props.adhocDisc * -1.0, true);

    const margin = props.asPercentage ? getMarginPercentage(salePrice, discCost) : getMarkupAmount(salePrice, discCost);
    const markup = props.asPercentage ? getMarkupPrecentage(salePrice, discCost) : getMarkupAmount(salePrice, discCost);

    const toggle = level == 0 || level == 3 ? null : <span>{props.expanded ? "-" : "+"}</span>;

    const costLimit = (props.costLimited) ? "L" : null;
    const retailLimit = (props.retailLimited) ? "L" : null;

    const myOption = props.isMyOption ? <span><i>(not for supplier)</i></span> : null;

    return <tr className={`acc-price-dtl-row-${level}`} onClick={() => {
        if (level != 0 && level != 3 && props.onClick) props.onClick(props);
    }}>
        <td style={{ textAlign: "left" }}>{toggle}<span id={props.id} className={`title-lvl-${level}`}>{props.title}{myOption}</span>{level == 1 && <AccentTooltip target={props.id} html={props.toolTip} />} </td>
        <td>{format.formatCurrency(props.cost, true)}{costLimit}</td>
        <td>{disc}</td>
        <td>{adhocCostDisc}</td>
        <td>{format.formatCurrency(discCost, true)}</td>
        <td>{format.formatCurrency(props.retail, true)}{retailLimit}</td>
        <td>{custDisc}</td>
        <td>{adhocDisc}</td>
        <td>{format.formatCurrency(salePrice, false)}</td>
        <td>{markup}</td>
        <td>{margin}</td>
    </tr >;
};


export class JobPriceDetailsErrorCtrl extends React.Component {

    static getDerivedStateFromError(error) {
        return { error: error };
    }

    constructor(props) {
        super(props);

        this.state = { error: null };

        this.componentDidCatch = this.componentDidCatch.bind(this);
    }

    componentDidCatch(error, errorInfo) {

    }


    render() {

        if (!accentUtils.isEmpty(this.state.error)) {
            return <Alert color="danger">
                An error occured while eveluating price details. Please contact support.
            </Alert>;
        }


        return <div>
            {this.props.children}
        </div>;

    }
}

export class JobPriceDetailsCtrl extends React.Component {

    constructor(props) {
        super(props);




        this.state = {
            jobData: props.lines,
            expanded: {},
            asPercentage: true,
            incTax: false,
        };


        lineCostDiscountHack = {};

        this.componentDidMount = this.componentDidMount.bind(this);

        this.decorateDesc = this.decorateDesc.bind(this);
        this.addToItem = this.addToItem.bind(this);
        this.getItem = this.getItem.bind(this);
        this.onItemClicked = this.onItemClicked.bind(this);
        this.onDiscountTypeChange = this.onDiscountTypeChange.bind(this);
        this.onTaxTypeChange = this.onTaxTypeChange.bind(this);
        this.getOptionValueName = this.getOptionValueName.bind(this);

    }


    componentDidMount() {

        var me = this;

        if (accentUtils.isNull(this.state.jobData)) {
            query_Job_PriceDetails.getAll({ jobID: this.props.jobID }).then(r => {
                me.setState({ jobData: r });
            });
        }

    }



    onDiscountTypeChange(e) {

        this.setState({ asPercentage: e.value === 1 });

    }

    onTaxTypeChange(e) {
        this.setState({ incTax: e.value === 1 });
    }

    addToItem(dest, source) {

        if (source != null) {
            dest.costAdhoc += source.costAdhoc;
            dest.adhocDisc += source.adhocDisc;
            dest.cost += source.cost;
            dest.discount += source.discount;
            dest.discountRetail += source.discountRetail;
            dest.retail += source.retail;
        }
    }

    decorateDesc(desc, isDim, isPerc) {

        if (isDim && isPerc) {
            return `${desc} (D|%)`;
        }

        if (isDim) {
            return `${desc} (D)`;
        }

        if (isPerc) {
            return `${desc} (%)`;
        }

        return desc;

    }

    getItem(id, level, title, cost, disc, costAdhoc, retail, discRetail, adhocDisc, toolTip, salesTaxRate, costTaxRate, nullIfNoVal, costLimited, retailLimited) {

        salesTaxRate = accentUtils.isNull(salesTaxRate) ? 0 : salesTaxRate;
        costTaxRate = accentUtils.isNull(costTaxRate) ? 0 : costTaxRate;

        const saleTaxFactor = this.state.incTax ? ((100.00 + salesTaxRate) / 100.00) : 1;
        const costTaxFactor = this.state.incTax ? ((100.00 + costTaxRate) / 100.00) : 1;

        const toNum = v => {

            if (v) {
                return Number(v);
            }

            return 0;
        };


        const hasValue = (Math.abs(toNum(cost)) + Math.abs(toNum(disc)) + Math.abs(toNum(costAdhoc)) + Math.abs(toNum(retail)) + Math.abs(toNum(discRetail)) + Math.abs(toNum(adhocDisc))) > 0;        

        console.log("costAdhoc  ", costAdhoc)
        if (!hasValue && nullIfNoVal) return null;

        return {
            id: id,
            level: level,
            title: title,
            cost: cost * costTaxFactor,
            discount: disc * costTaxFactor,
            costAdhoc: costAdhoc,
            discountRetail: discRetail * saleTaxFactor,
            retail: retail * saleTaxFactor,
            adhocDisc: adhocDisc,// * saleTaxFactor,
            onClick: this.onItemClicked,
            asPercentage: this.state.asPercentage,
            toolTip: accentUtils.isNull(toolTip) ? '' : toolTip,
            salesTaxRate: salesTaxRate,
            costTaxRate: costTaxRate,
            costLimited: costLimited,
            retailLimited: retailLimited
        };

    }

    onItemClicked(item) {

        var expanded = this.state.expanded[item.id];

        var newState = { ...this.state.expanded };

        newState[item.id] = !expanded;

        this.setState({ expanded: newState });


    }

    getOptionValueName(olos, priceModel, valueID) {

        if (accentUtils.isEmpty(valueID)) {
            return '';
        }

        return from(olos).where(i => i.ProductOptionValueID.toUpperCase() == valueID.toUpperCase())
            .select(i => i.ProductOptionValueName
            ).firstOrDefault();
    }

    render() {

        var me = this;




        if (accentUtils.isNull(this.state.jobData)) {
            return <AccentSpinner />;
        }

        const jobItem = me.getItem(-1, 0, "TOTAL", 0, 0, 0, 0, 0, 0, false);



        const rows = [];

        this.state.jobData.map(l => {

            const priceModel = JSON.parse(l.PriceModel);
            const olos = from(JSON.parse(l.OrderLineOptionsData))
                .groupBy(o => o.ProductOptionID.toUpperCase())
                .select(o => {

                    var result = o.getSource()[0];

                    if (result.IsExtra) {

                        var items = o.getSource();


                        if (items.length > 1) {

                            var customValue = from(items).select(i => window.InsyteProduct.InsyteWeb.InsyteWebDiscountHelper.GetExtraInfo(i.CustomValue, i.AdditionalValue))
                                .groupBy(i => i.UnitTypeDescription).
                                select(g => `${from(g.getSource()).sum(v => Number(v.Qty)).toFixed(2)} ${g.key()}`)
                                .toArray()
                                .join();


                            return { ...result, CustomValue: customValue };

                        }

                        return result;

                    }

                    return result;

                }).toArray();


            const product = ProductService.getProduct(l.ProductID);


            lineCostDiscountHack[l.ID] = me.state.incTax ? l.CostPriceIncTax : l.CostPrice;

            const tt = accentUtils.isNull(priceModel) ? "No pricing details available" :
                `Price List : ${priceModel.PriceListName} v${accentUtils.isEmpty(priceModel.PriceListVersion) ? "0" : priceModel.PriceListVersion}<br>Pricing 1: ${priceModel.UseGroupCodeOption1 ? priceModel.PricingOption1GroupCode : me.getOptionValueName(olos, priceModel, priceModel.PricingOption1ValueID)}<br>Pricing 2: ${priceModel.UseGroupCodeOption2 ? priceModel.PricingOption2GroupCode : me.getOptionValueName(olos, priceModel, priceModel.PricingOption2ValueID)}<br>Sales Tax Rate: ${l.SalesTaxRate}%<br>Cost Tax Rate: ${l.CostTaxRate}%`;

            const lineItem = accentUtils.isNull(priceModel)
                ? me.getItem(
                    `line_${l.ID}`,
                    1,
                    `${l.JobLineNo} ${l.LocationOther}: ${product.ProductName} x ${l.Qty} (${l.Width} x ${l.Drop})`,
                    l.OriginalCostPrice,
                    0,
                    (me.state.incTax ? l.CostPriceIncTax - l.OriginalCostPriceIncTax : l.CostPrice - l.OriginalCostPrice),
                    l.Price,
                    0, (me.state.incTax ? l.SalePriceIncTax - l.PriceIncTax : l.SalePrice - l.Price),
                    tt,
                    l.SalesTaxRate,
                    l.CostTaxRate,
                    false)
                : me.getItem(
                    `line_${l.ID}`,
                    1,
                    `${l.JobLineNo} ${l.LocationOther}: ${product.ProductName} - v${accentUtils.isEmpty(l.ProductVersion) ? "0" : l.ProductVersion} x ${l.Qty} (${l.Width} x ${l.Drop})`,
                    0,
                    0,
                    0,
                    0,
                    0,
                    (me.state.incTax ? l.SalePriceIncTax - l.PriceIncTax : l.SalePrice - l.Price), tt, l.SalesTaxRate, l.CostTaxRate, false);

            if (accentUtils.isNull(priceModel)) {
                rows.push(<JobPriceDetailsLineCtrl key={l.ID} {...lineItem} expanded={false} />);
                me.addToItem(jobItem, lineItem);
                return;
            }


            const basePriceItem = from(priceModel.CostItems).where(i => i.ItemID.toUpperCase() == l.ProductID.toUpperCase()).firstOrDefault();

            const baseItem = me.getItem(`line_base_${l.ID}`, 2, "BASE", 0, 0, 0, 0, 0, 0, null, l.SalesTaxRate, l.CostTaxRate, false);

            var baseAmountItem = null;
            var baseDeliveryItem = null;
            var baseInstallItem = null;
            var baseAmountItemDim = null;
            var baseDeliveryItemDim = null;
            var baseInstallItemDim = null;

            if (!accentUtils.isNull(basePriceItem)) {

                baseAmountItem = me.getItem(`line_base_amount_${l.ID}`, 3, me.decorateDesc("AMOUNT", false, basePriceItem.CostIsPerc), basePriceItem.Cost, basePriceItem.StandardCostDiscount, null, basePriceItem.RetailPrice, basePriceItem.StandardRetailDiscount, 0, null, l.SalesTaxRate, l.CostTaxRate, true);
                baseAmountItemDim = me.getItem(`line_base_amount_dim_${l.ID}`, 3, me.decorateDesc("AMOUNT", true, basePriceItem.DimCostIsPerc), basePriceItem.DimCost, basePriceItem.StandardDimCostDiscount, null, basePriceItem.DimRetailPrice, basePriceItem.StandardDimRetailDiscount, 0, null, l.SalesTaxRate, l.CostTaxRate, true);
                baseDeliveryItem = me.getItem(`line_base_delivery_${l.ID}`, 3, me.decorateDesc("DELIVERY", false, basePriceItem.CostDeliveryIsPerc), basePriceItem.CostDelivery, basePriceItem.StandardCostDeliveryDiscount, null, basePriceItem.DeliveryPrice, basePriceItem.StandardRetailDeliveryDiscount, 0, null, l.SalesTaxRate, l.CostTaxRate, true);
                baseDeliveryItemDim = me.getItem(`line_base_delivery_dim_${l.ID}`, 3, me.decorateDesc("DELIVERY", true, basePriceItem.DimCostDeliveryIsPerc), basePriceItem.DimCostDelivery, basePriceItem.StandardDimCostDeliveryDiscount, null, basePriceItem.DimDeliveryPrice, basePriceItem.StandardDimRetailDeliveryDiscount, 0, null, l.SalesTaxRate, l.CostTaxRate, true);
                baseInstallItem = me.getItem(`line_base_install_${l.ID}`, 3, me.decorateDesc("INSTALL", false, basePriceItem.CostInstallIsPerc), basePriceItem.CostInstall, basePriceItem.StandardCostInstallDiscount, null, basePriceItem.InstallPrice, basePriceItem.StandardRetailInstallDiscount, 0, null, l.SalesTaxRate, l.CostTaxRate, true);
                baseInstallItemDim = me.getItem(`line_base_install_dim_${l.ID}`, 3, me.decorateDesc("INSTALL", true, basePriceItem.DimCostInstallIsPerc), basePriceItem.DimCostInstall, basePriceItem.StandardDimCostInstallDiscount, null, basePriceItem.DimInstallPrice, basePriceItem.StandardDimRetailInstallDiscount, 0, null, l.SalesTaxRate, l.CostTaxRate, true);



                me.addToItem(baseItem, baseAmountItem);
                me.addToItem(baseItem, baseAmountItemDim);
                me.addToItem(baseItem, baseDeliveryItem);
                me.addToItem(baseItem, baseDeliveryItemDim);
                me.addToItem(baseItem, baseInstallItem);
                me.addToItem(baseItem, baseInstallItemDim);

            }
            me.addToItem(lineItem, baseItem);

            const options = [];

            from(olos).orderBy(o => o.ProductOptionGroupSort)
                .thenBy(o => o.ProductOptionSort)
                .thenBy(o => o.ProductOptionValueSort)
                .select(o => {
                    const costItems = from(priceModel.CostItems).where(c => c.ItemID.toUpperCase() == o.ProductOptionValueID.toUpperCase() || c.ItemID.toUpperCase() == o.ProductOptionID.toUpperCase() || c.ItemID.toUpperCase() == o.ProductOptionGroupID.toUpperCase()).orderBy(c => c.Type).toArray();


                    if (accentUtils.isNull(costItems) || costItems.length == 0) {
                        return null;
                    }


                    for (var i = 0; i < costItems.length; i++) {

                        const c = costItems[i];


                        const desc = (c.Type == 2 && !o.IsExtra) ? o.ProductOptionName : `${o.ProductOptionName} - ${o.CustomValue}`;

                        const optionItem = me.getItem(`line_option_${l.ID}_${c.ItemID}_${c.Type}`, 2, desc, 0, 0, 0, 0, 0, 0);

                        const optionAmountItem = me.getItem(`line_option_amount_${l.ID}_${c.ItemID}_${c.Type}`, 3, me.decorateDesc("AMOUNT", false, c.CostIsPerc), c.Cost, c.StandardCostDiscount, null, c.RetailPrice, c.StandardRetailDiscount, 0, null, l.SalesTaxRate, l.CostTaxRate, true, c.CostLimited, c.RetailPriceLimited);
                        const optionDeliveryItem = me.getItem(`line_option_delivery_${l.ID}_${c.ItemID}_${c.Type}`, 3, me.decorateDesc("DELIVERY", false, c.CostDeliveryIsPerc), c.CostDelivery, c.StandardCostDeliveryDiscount, null, c.DeliveryPrice, c.StandardRetailDeliveryDiscount, 0, null, l.SalesTaxRate, l.CostTaxRate, true, c.CostDeliveryLimited, c.DelivieryPriceLimited);
                        const optionInstallItem = me.getItem(`line_option_install_${l.ID}_${c.ItemID}_${c.Type}`, 3, me.decorateDesc("INSTALL", false, c.CostInstallIsPerc), c.CostInstall, c.StandardCostInstallDiscount, null, c.InstallPrice, c.StandardRetailInstallDiscount, 0, null, l.SalesTaxRate, l.CostTaxRate, true, c.CostInstallLimited, c.InstallPriceLimited);

                        var optionAmountItemDim = null;
                        var optionDeliveryItemDim = null;
                        var optionInstallItemDim = null;

                        me.addToItem(optionItem, optionAmountItem);
                        me.addToItem(optionItem, optionDeliveryItem);
                        me.addToItem(optionItem, optionInstallItem);


                        if (c.Type == 3) { // dim on value only
                            optionAmountItemDim = me.getItem(`line_option_amount_${l.ID}_${c.ItemID}_${c.Type}_dim`, 3, me.decorateDesc("AMOUNT", true, c.DimCostIsPerc), c.DimCost, c.StandardDimCostDiscount, null, c.DimRetailPrice, c.StandardDimRetailDiscount, 0, null, l.SalesTaxRate, l.CostTaxRate, true, c.DimCostLimited, c.DimRetailPriceLimited);
                            optionDeliveryItemDim = me.getItem(`line_option_delivery_${l.ID}_${c.ItemID}_${c.Type}_dim`, 3, me.decorateDesc("DELIVERY", true, c.DimCostDeliveryIsPerc), c.DimCostDelivery, c.StandardDimCostDeliveryDiscount, null, c.DimDeliveryPrice, c.StandardDimRetailDeliveryDiscount, 0, null, l.SalesTaxRate, l.CostTaxRate, true, c.DimCostDeliveryLimited, c.DimDelivieryPriceLimited);
                            optionInstallItemDim = me.getItem(`line_option_install_${l.ID}_${c.ItemID}_${c.Type}_dim`, 3, me.decorateDesc("INSTALL", true, c.DimCostInstallIsPerc), c.DimCostInstall, c.StandardDimCostInstallDiscount, null, c.DimInstallPrice, c.StandardDimRetailInstallDiscount, 0, null, l.SalesTaxRate, l.CostTaxRate, true, c.DimCostInstallLimited, c.DimInstallPriceLimited);


                            me.addToItem(optionItem, optionAmountItemDim);
                            me.addToItem(optionItem, optionDeliveryItemDim);
                            me.addToItem(optionItem, optionInstallItemDim);

                        }


                        me.addToItem(lineItem, optionItem);

                        const isMyOption = c.IsMyOption;

                        options.push(<JobPriceDetailsLineCtrl key={optionItem.id} {...optionItem} expanded={me.state.expanded[optionItem.id]} isMyOption={isMyOption} />);

                        if (me.state.expanded[optionItem.id]) {
                            if (optionAmountItem != null) {
                                options.push(<JobPriceDetailsLineCtrl key={optionAmountItem.id} {...optionAmountItem} />);
                            }
                            if (optionAmountItemDim != null) {
                                options.push(<JobPriceDetailsLineCtrl key={optionAmountItemDim.id} {...optionAmountItemDim} />);
                            }
                            if (optionDeliveryItem != null) {
                                options.push(<JobPriceDetailsLineCtrl key={optionDeliveryItem.id} {...optionDeliveryItem} />);
                            }
                            if (optionDeliveryItemDim != null) {
                                options.push(<JobPriceDetailsLineCtrl key={optionDeliveryItemDim.id} {...optionDeliveryItemDim} />);
                            }
                            if (optionInstallItem != null) {
                                options.push(<JobPriceDetailsLineCtrl key={optionInstallItem.id} {...optionInstallItem} />);
                            }
                            if (optionInstallItemDim != null) {
                                options.push(<JobPriceDetailsLineCtrl key={optionInstallItemDim.id} {...optionInstallItemDim} />);
                            }

                        }
                    }
                    return null;

                }).toArray();


            lineItem.costAdhoc = (def(lineItem.cost, 0) + def(lineItem.discount, 0) - def(lineCostDiscountHack[l.ID], 0)) * -1;

            me.addToItem(jobItem, lineItem);

            rows.push(<JobPriceDetailsLineCtrl key={l.ID} {...lineItem} expanded={me.state.expanded[lineItem.id]} />);

            if (me.state.expanded[lineItem.id]) {

                rows.push(<JobPriceDetailsLineCtrl key={`base_${l.ID}`} {...baseItem} expanded={me.state.expanded[baseItem.id]} />);

                if (me.state.expanded[baseItem.id]) {

                    if (!accentUtils.isNull(baseAmountItem))
                        rows.push(<JobPriceDetailsLineCtrl key={`base_amount_${l.ID}`} {...baseAmountItem} />);
                    if (!accentUtils.isNull(baseAmountItemDim))
                        rows.push(<JobPriceDetailsLineCtrl key={`base_amount_dim_${l.ID}`} {...baseAmountItemDim} />);
                    if (!accentUtils.isNull(baseDeliveryItem))
                        rows.push(<JobPriceDetailsLineCtrl key={`base_delivery_${l.ID}`} {...baseDeliveryItem} />);
                    if (!accentUtils.isNull(baseDeliveryItemDim))
                        rows.push(<JobPriceDetailsLineCtrl key={`base_delivery_dim_${l.ID}`} {...baseDeliveryItemDim} />);
                    if (!accentUtils.isNull(baseInstallItem))
                        rows.push(<JobPriceDetailsLineCtrl key={`base_install_${l.ID}`} {...baseInstallItem} />);
                    if (!accentUtils.isNull(baseInstallItemDim))
                        rows.push(<JobPriceDetailsLineCtrl key={`base_install_dim_${l.ID}`} {...baseInstallItemDim} />);

                }

                options.map(o => rows.push(o));

            }


        });


        rows.unshift(<JobPriceDetailsLineCtrl key={`job_${me.props.jobID}`} {...jobItem} />);

        var optionFilter = [
            { ID: 1, Text: t("application_strings.views.job.percentage"), tagName: 'Percentage' },
            { ID: 2, Text: t("application_strings.views.job.dollar"), tagName: 'Dollar' },
        ];

        var btns = <ToolbarToggleButton defaultValue={1} options={optionFilter} onChange={this.onDiscountTypeChange} />;


        var optionTax = [
            { ID: 1, Text: t("application_strings.views.job.incTax"), tagName: 'IncTax' },
            { ID: 2, Text: t("application_strings.views.job.exTax"), tagName: 'ExTax' },
        ];

        var btnsTax = <ToolbarToggleButton defaultValue={2} options={optionTax} onChange={this.onTaxTypeChange} />;


        return <Table responsive>
            <thead>
                <tr style={{ textAlign: "right" }}>
                    <th>{btnsTax} {btns}</th>
                    <th>COST</th>
                    <th>COST DISC</th>
                    <th>COST ADHOC</th>
                    <th>DISC COST</th>
                    <th>RETAIL</th>
                    <th>DISC CUST</th>
                    <th>DISC ADHOC</th>
                    <th>SALE PRICE</th>
                    <th>MARKUP</th>
                    <th>MARGIN</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </Table>;
    }
}

